import LogoSmk from "assets/img/login/logosmk.png";
import useAuthStore from "authStore";
import GlobalNotif from "components/notification/globalNotification";
import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [credential, setCredential] = useState({
        username:'',
        email:'',
        password:'',
        role:'1',
    });

    const navigate = useNavigate();
    const setLoginData = useAuthStore((state) => state.setLoginData);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredential({
            ...credential,
            [name]: value,
        });
    };

    //inside component function
    const [showPassword, setShowPassword] = useState(false);

    //function to togle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        setLoading(true);
        //change new logic
        try {
            //determine the api based on role id siswa.
            //jika role id siswa maka gunakan endpoint yang lama, jika role id bukan siswa gunakan endpoint yang baru
            const apiEndpointLogin = credential.role === '1' ? `${apiUrl}/v1/login`: `${apiUrl}/v1/guru/loginGuru`;
            console.log(apiEndpointLogin);

            //handling requestBody
            const requestBody = {
                username: credential.username,
                email: credential.email,
                password: credential.password
            };
            e.preventDefault();
            fetch(apiEndpointLogin, {
                method: 'POST',
                headers: {
                    'Content-Type':'application/json',
                },
                body: JSON.stringify(requestBody),
            })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('Response Data from BE: ', responseData);
                if (responseData.status === 'ok') {
                    setLoginData({
                        isLoggedIn: true,
                        role: responseData.data.role || responseData.data.id_role,
                        roleName: responseData.data.role_name,
                        email: responseData.data.email,
                        authorizationToken: responseData.data.tokens.access.token,
                        nama: responseData.data.nama,
                        nisn: responseData.data.nisn,
                      });
                    if (responseData.data.role_name === 'Admin'){
                        // localStorage.setItem('islogin', true);
                        navigate("/admin/default");
                        setLoading(false);
                    }else {
                        navigate("/admin/keuangan");
                        setLoading(false);
                    }   

                    let role;
                    
                    if(responseData.data.role != null){
                        role  = responseData.data.role;
                    }else{
                        role  = responseData.data.id_role;
                    }
                
                    const nama_role  = responseData.data.role_name;
                    const tokens = responseData.data.tokens;
                    // localStorage.setItem('role', role);
                    // localStorage.setItem('nama_role', nama_role);
                    // localStorage.setItem('email', responseData.data.email);
                    // localStorage.setItem('authorization', tokens.access.token);
                    // localStorage.setItem('nama', responseData.data.nama);
                    // localStorage.setItem('nisn', responseData.data.nisn);
                }else{
                    setLoading(false);
                    // alert(responseData.message);
                    setAlertMessage(responseData.message);
                }
            })
        } catch (error) {
            console.error(error);
            setLoading(false);
            // Handle any other errors, such as network errors
            // alert('An error occurred. Please try again later.');
            setAlertMessage("An error occurred. Please try again later.");
        }
    };




    return(
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-24 w-auto"
                    src={LogoSmk}
                    alt="Logo SMK NU TUlis"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Masuk ke Akun
                </h2>
            </div>

            {/* isi form */}
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                        Username
                        </label>
                        <div className="mt-2">
                            <input
                                id="username"
                                name="username"
                                value={credential.username}
                                onChange={handleChange}
                                type="text"
                                autoComplete="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email Address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={credential.email}
                                onChange={handleChange}
                                autoComplete="email"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                        </div>
                    </div>

                    <div className="relative">
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            <div className="text-sm">
                                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                                    Forgot password?
                                </a>
                            </div>
                        </div>
                        <div className="mt-2 relative">
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={credential.password}
                                onChange={handleChange}
                                autoComplete="current-password"
                                required
                                className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 px-3 flex items-center focus:outline-none"
                                onClick={togglePasswordVisibility} // Toggle password visibility when button is clicked
                            >
                                {showPassword ? (
                                    <Eye
                                        size={20}
                                        className="text-gray-500 hover:text-indigo-500 cursor-pointer"
                                    />
                                ) : (
                                    <EyeOff
                                        size={20}
                                        className="text-gray-500 hover:text-indigo-500 cursor-pointer"
                                    />
                                )}
                            </button>
                        </div>
                    </div>


                    <div>
                        <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                            Login Sebagai
                        </label>
                        <select 
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleChange}
                            value={credential.role}
                            name="role"
                        >
                            <option value="1">Siswa</option>
                            <option value="2">Guru</option>
                            <option value="3">Bendahara Sekolah</option>
                            <option value="4">Bendahara Bos</option>
                            <option value="5">Kepala Sekolah</option>
                            <option value="6">Admin</option>
                        </select>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-lime-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                            >
                                Log In
                            </button>
                            {loading && <GlobalNotif />}
                            {/* Show custom alert modal */}
                            {alertMessage && (
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                    <div className="bg-white rounded-lg p-8 max-w-md flex flex-col items-center">
                                        <div className="text-lg font-semibold mb-2">Alert</div>
                                        <div className="text-sm text-gray-700">{alertMessage}</div>
                                        <button 
                                            className="mt-4 px-4 py-2 bg-lime-600 text-white rounded-lg"
                                            onClick={() => setAlertMessage("")}
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            )}

                    </div>
                
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member?{' '}

                </p>
            </div>
        </div>
    )
}

export default Login;
