import Card from "components/card";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React from "react";

const ConfigNilaiMaks = () => {
    const [open, setOpen] = React.useState(true);

    return(
        <div className="flex h-full w-full">
            <Sidebar open={open} onClose={() => setOpen(false)}/>
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                <main className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}>
                    <div className="h-full">
                        <Navbar />
                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white">
                            <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
                                <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
                                    <Card extra ={"w-full p-4 h-full"}>
                                        <div className="w-full">
                                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                                Config Nilai Maks Pemasukan SPP Kelas X
                                            </h4>
                                            <p className="mt-2 text-base text-gray-600">
                                                Silahkan Untuk di masukan nilai batas Maksimal Pemasukan Kategori SPP kelas X
                                            </p>
                                        </div>
                                        {/* Project */}
                                        <div className="w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                            <div className="items-center">
                                                <form action="#" method="POST" className="mx-auto mt-0 max-w-xl sm:mt-2">
                                                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="kelas" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                                                Kelas
                                                            </label>
                                                            <div className="mt-0">
                                                                <input
                                                                    type="text"
                                                                    name="petugas_penarikan"
                                                                    id="petugas_penarikan"
                                                                    placeholder="Kelas"
                                                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="sm:col-span-2 mt-2">
                                                        <label htmlFor="nominal_bulan" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                                            Target Pemasukan
                                                        </label>
                                                        <div className="mt-0">
                                                            <input
                                                                type="number"
                                                                name="nominal_bulan"
                                                                id="nominal_bulan"
                                                                //value={data.nominal_bulan}
                                                                //onChange={handleChange}
                                                                placeholder="target Total Pemasukan"
                                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="sm:col-span-2 mt-2">
                                                        <fieldset>
                                                        <legend className="text-sm text-left font-semibold leading-6 text-gray-900">Pilih Jenis Pembayaran</legend>
                                                            <div className="mt-2 space-y-6">
                                                                <div className="flex items-center gap-x-3 mt-0">
                                                                    <input
                                                                        id="jenis_transaksi"
                                                                        name="jenis_transaksi"
                                                                        type="radio"
                                                                        value="SPP"
                                                                        //onChange={handleChange}
                                                                        className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-600"
                                                                    />
                                                                    <label htmlFor="jenis_transaksi" className="block text-sm font-medium leading-6 text-gray-900">
                                                                        SPP
                                                                    </label>
                                                                </div>
                                                                <div className="flex items-center gap-x-3">
                                                                    <input
                                                                        id="jenis_transaksi"
                                                                        name="jenis_transaksi"
                                                                        type="radio"
                                                                        value="PPDB"
                                                                        //onChange={handleChange}
                                                                        className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-600"
                                                                    />
                                                                    <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                                                                        PPDB
                                                                    </label>
                                                                </div>
                                                                <div className="flex items-center gap-x-3">
                                                                    <input
                                                                        id="jenis_transaksi"
                                                                        name="jenis_transaksi"
                                                                        type="radio"
                                                                        value="Praktik"
                                                                        //onChange={handleChange}
                                                                        className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-600"
                                                                    />
                                                                    <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                                                                        UANG PRAKTIKUM
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                    <div className="mt-10">
                                                        <button
                                                            type="submit"
                                                            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            Submit Penambahan Petugas
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
                                    <p>Testing page halamany</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
};


export default ConfigNilaiMaks;