import { useState } from "react";
import { TEToast } from "tw-elements-react";

function NotifSuccessRegisGUru(){
    const [open, setOpen] = useState(true);
    return(
        <div>
            <TEToast
                staticToast
                open={open}
                color="bg-success-100 text-success-700"
                className="mb-6"
            >
            <div className="flex items-center justify-between rounded-t-lg border-b-2 border-success/20 bg-clip-padding px-4 pb-2 pt-2.5">
            <p className="flex items-center font-bold">
                <span className="[&>svg]:w-4 [&>svg]:h-4 mr-2 -mt-[2px]">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                >
                    <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd"
                    />
                </svg>
                </span>
                Register Guru
            </p>
            <div className="flex items-center">
                <p className="text-xs text-success-700">11 mins ago</p>
                <button
                type="button"
                className="ml-2 box-content rounded-none border-none opacity-80 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setOpen(false)}
                aria-label="Close"
                >
                <span className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                    >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                    </svg>
                </span>
                </button>
            </div>
            </div>
        <div className="break-words rounded-b-lg px-4 py-4">
            Pendaftaran Guru Berhasil
        </div>
        </TEToast>
        </div>
    )
}

export default NotifSuccessRegisGUru;