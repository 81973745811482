import { MdAdminPanelSettings, MdAppRegistration, MdHome, MdInput, MdMoney, MdPerson } from "react-icons/md";
import Administration from "views/admin/administration";
import Dataguru from "views/admin/dataguru";
import MainDashboard from "views/admin/default";
import Keuangan from "views/admin/keuangan";
import Pembayaran from "views/admin/pembayaran";
import PembayaranQR from "views/admin/pembayaranQR";
import Profile from "views/admin/profile";
import Registration from "views/admin/registration";
import RegistrationGuru from "views/admin/registrationguru";

export const routes = [
    {
        name: "Main Dashboard",
        layout: "/admin",
        path: "default",
        icon: <MdHome className="h-6 w-6" />,
        component: <MainDashboard />,
        role: 2,
        rolesadmin: "Admin",
    },
    {
        name: "Registration",
        layout: "/admin",
        path: "registration",
        icon: <MdAppRegistration className="h-6 w-6" />,
        component: <Registration />,
        role: "3",
        rolesadmin: "Admin",
    },
    {
        name: "Registration Guru",
        layout: "/admin",
        path: "registerguru",
        icon: <MdAppRegistration className="h-6 w-6" />,
        component: <RegistrationGuru />,
        role: "3",
        rolesadmin: "Admin",
    },
    {
        name: "Keuangan",
        layout: "/admin",
        path: "keuangan",
        icon: <MdMoney className="h-6 w-6" />,
        component: <Keuangan />,
        role: 2,
        rolesadmin: "Admin",
    },
    {
        name: "Profile",
        layout: "/admin",
        path: "profile",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Profile />,
        role: "1",
        rolesadmin: "Siswa",
    },
    {
        name: "Pembayaran",
        layout: "/admin",
        path: "pembayaran",
        icon: <MdInput className="h-6 w-6" />,
        component: <Pembayaran />,
        role: 2,
        rolesadmin: "Admin",
    },
    {
        name: "Input Pembayaran QR",
        layout: "/admin",
        icon: <MdInput className="h-6 w-6" />,
        component: <PembayaranQR />,
        role: 2,
        rolesadmin: "Admin",
    },
    {
        name: 'Data Guru',
        layout: '/admin',
        path: 'dataguru',
        icon: <MdPerson className="h-6 w-6" />,
        component: <Dataguru />,
        role: 2,
        rolesadmin: "Admin",
    },
    {
        name: 'Administration',
        layout: '/admin',
        path: 'administration',
        icon: <MdAdminPanelSettings className="h-6 min-6" />,
        component: <Administration />,
        role: 2,
        rolesadmin: "Admin",
    }
    
];

export default routes;
