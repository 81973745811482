import { useEffect, useState } from "react";

const DetailTableSpp = () =>{

    const [queryParams, setQueryParams] = useState({
        bulan_bayar:'Januari',
    });

    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const pageSize = 10;



    useEffect(() =>{
        fetchData();
    }, [queryParams, currentPage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setQueryParams((prevParams) =>({
            ...prevParams,
            [name]: value,
        }));
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchData = () => {
        const queryString = Object.keys(queryParams)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .concat(`page=${currentPage}`, `pageSize=${pageSize}`)
            .join('&');
    
        fetch(`${apiUrl}/v1/laporanPembayaran/getDataSpp?${queryString}`)
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then((result) => {
            console.log('Success:', result);
            if (result.status === 'ok' && result.data.responseData && Array.isArray(result.data.responseData)) {
                setTableData(result.data.responseData);
                setTotalCount(result.data.totalCount);
                setTotalPages(Math.ceil(result.data.totalCount / pageSize));
            } else {
                console.error('Invalid data structure:', result);
                setTableData([]); // Set an empty array as a fallback
                setTotalCount(0);
                setTotalPages(1);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            setTableData([]);
            setTotalCount(0);
            setTotalPages(1);
        });
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return(
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="flex items-center">
                    <label className="text-base font-semibold leading-6 text-gray-900 mr-3">
                        Table detail transaksi SPP bulan
                    </label>
                    <div className="relative mt-2.5">
                        <form onSubmit={(e) => {fetchData();}}>
                            <select
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                name="bulan_bayar"
                                id="bulan_bayar"
                                type="text"
                                value={queryParams.bulan_bayar}
                                onChange={handleChange}
                            >
                                <option value="Januari">Januari</option>
                                <option value="Februari">Februari</option>
                                <option value="Maret">Maret</option>
                                <option value="April">April</option>
                                <option value="Mei">Mei</option>
                                <option value="Juni">Juni</option>
                                <option value="Juli">Juli</option>
                                <option value="Agustus">Agustus</option>
                                <option value="September">September</option>
                                <option value="Oktober">Oktober</option>
                                <option value="November">November</option>
                                <option value="Desember">Desember</option>
                            </select>
                        </form>
                    </div>
                </div>

                    
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full  divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Nama
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Kelas
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    NISN
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Jumlah Bayar
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Tanggal Bayar
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Petugas Input
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Jenis Pembayaran
                                </th>
                            </tr>
                        </thead>
                    <tbody className="bg-white">
                    {tableData.map((transaction) => (
                    <tr key={transaction.id} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 sm:pl-2">
                        {transaction.nama_siswa}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.kelas}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.nisn}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.nominal_bulan}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.tanggal_bayar}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.petugas_input}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.jenis_transaksi}</td>
                    </tr>
                    ))}
                </tbody>
            </table>
            <nav
                            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                            aria-label="Pagination"
                        >
                        <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">{(currentPage - 1) * pageSize + 1}</span> to{' '}
                                <span className="font-medium">{Math.min(currentPage * pageSize, totalCount)}</span> of{' '}
                                <span className="font-medium">{totalCount}</span> results
                            </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                            >
                                Previous
                            </button>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                            >
                                Next
                            </button>
                        </div>
                        </nav>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default DetailTableSpp;