import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoSmk from "../../assets/img/login/logosmk.png";


const SignUpGuru = () => {
    const [data, setData] = useState({
        nama:'',
        username:'',
        email:'',
        password:'',
        role_name:'',
        id_role:'',
    });

    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submit data : ",data);
        fetch(`${apiUrl}/v1/guru/createAccountGuru`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => {
            if(!response.ok){
                throw new Error('Network response was not oke');
            }else{
                return response.json();
            }
        })
        .then((data) => {
            if (data.status === 'ok'){
                navigate("/login");
                window.location.reload();
                console.log("masuk ke halaman login")
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value });
    };

    const handleChangeRole = (e) => {
        const selectedRoleId = parseInt(e.target.value, 10);
        const selectedRoleName = e.target.options[e.target.selectedIndex].text;

        setData((prevData) => ({
            ...prevData,
            id_role: selectedRoleId,
            role_name: selectedRoleName,
        }));
    }

    return(
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-24 w-auto"
                    src={LogoSmk}
                    alt="Logo SMK NU TUlis"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                   Create Account Guru
                </h2>
            </div>
            {/* form */}
            <div className="bg-white p-10 md:w-3/4 lg:w-1/2 mx-auto">
                <form onSubmit={handleSubmit}>

                    <div className="flex items-center mb-5">
                        <label htmlFor="nama" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Nama Lengkap
                        </label>
                        <input 
                            type="text" 
                            id="nama"
                            name="nama"
                            value={data.nama}
                            onChange={handleChange}
                            placeholder="masukkan nama lengkap"
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>

                    <div className="flex items-center mb-5">
                        <label htmlFor="username" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <input 
                            type="text" 
                            id="username"
                            name="username"
                            value={data.username}
                            onChange={handleChange}
                            placeholder="username untuk login"
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>

                    <div className="flex items-center mb-5">
                        <label htmlFor="email" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Email
                        </label>
                        <input 
                            type="email" 
                            id="email"
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                            placeholder="masukkan email terdaftar"
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>

                    <div className="flex items-center mb-5">
                        <label htmlFor="password" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Password
                        </label>
                        <input 
                            type="password" 
                            id="password"
                            name="password"
                            value={data.password}
                            onChange={handleChange}
                            placeholder="masukkan password minimal 5 digit"
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>

                    <div className="flex items-center mb-5">
                        <label htmlFor="id_role" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Select Role
                        </label>
                        <select 
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                            onChange={handleChangeRole}
                            value={data.id_role}
                            name="id_role"
                        >
                            <option value="1">Guru</option>
                            <option value="2">Bendahara Sekolah</option>
                            <option value="3">Bendahara Bos</option>
                            <option value="4">Kepala Sekolah</option>
                            <option value="5">Admin</option>
                        </select>
                    </div>

                    <div className="text-right">
                        <button
                            type="submit"
                            className="flex justify-center rounded-md bg-lime-600 px-8 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">
                                Create Account
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default SignUpGuru;