import Project from "./components/Project";
import Staff from "./components/Staff";

const Administration = () => {
    return(
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white">
            {/* card */}
            <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
                <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
                    <Project />
                </div>
                <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
                    <Staff />
                </div>
            </div>
        </div>
    )
}

export default Administration;