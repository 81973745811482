import Card from "components/card";
import { useEffect, useState } from "react";


const DataKasSekolah = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const pageSize = 15;

    useEffect(() => {
        fetchDataAllRiwayat();
    },[currentPage]);

    const fetchDataAllRiwayat = async () => {
        try {
            const response = await fetch(`${apiUrl}/v1/riwayatPembayaran/allDataRiwayat?page=${currentPage}&pageSize=${pageSize}`);
            if(!response.ok){
                throw new Error(`HTTP ERRPR !, Status : ${response.status}`);
            }

            const result = await response.json();

            if (result.status === 'ok' && result.data.responseData && Array.isArray(result.data.responseData)) {
                const newData = result.data.responseData.map((transaction, index) => ({
                    ...transaction,
                    isNewTransaction: index === 0,
                }));
                setTableData(newData);
                setTotalCount(result.data.totalCount);
                setTotalPages(Math.ceil(result.data.totalCount / pageSize));
            } else {
                console.error('Invalid data structure:', result);
                setTableData([]);
                setTotalCount(0);
                setTotalPages(1);
            }
        } catch (error) {
            console.error('Error: ', error);
            setTableData([]);
            setTotalCount(0);
            setTotalPages(1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
                Data Kas Pemasukan Sekolah
            </div>
        </header>

        <div className="mt-2 overflow-x-scroll xl:overflow-x-hidden">
        <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full  divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Nama Siswa
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    NISN
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Kelas
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Jenis Transaksi
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Nominal
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Bulan
                                </th>
                                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Status Transaksi
                                </th>
                            </tr>
                        </thead>
                            <tbody className=" bg-white">
                                {tableData.map((transaction, index) => (
                                <tr 
                                    key={transaction.id}
                                    className={`${index === 0 && transaction.isNewTransaction ? 'animate-blink bg-lime-100' : ''}
                                    ${index === 0 && transaction.isNewTransaction ? 'even:bg-gray-50' : ''}`}>
                                <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{transaction.nama_siswa}</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {transaction.nisn}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.kelas}</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.jenis_transaksi}</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.nominal}</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.bulan}</td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.status}</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                        <nav
                            className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                            aria-label="Pagination"
                        >
                        <div className="hidden sm:block">
                            <p className="text-sm text-gray-700">
                                Showing <span className="font-medium">{(currentPage - 1) * pageSize + 1}</span> to{' '}
                                <span className="font-medium">{Math.min(currentPage * pageSize, totalCount)}</span> of{' '}
                                <span className="font-medium">{totalCount}</span> results
                            </p>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                            >
                                Previous
                            </button>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                            >
                                Next
                            </button>
                        </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </Card>
    );
};

export default DataKasSekolah;