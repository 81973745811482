import LogoSmk from "assets/img/login/logosmk.png";
import useAuthStore from "authStore";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

const DataKeuanganSiswa = () =>{


    //connect BE
    console.log("data-kekurangan-siswa")
    const apiUrl = process.env.REACT_APP_API_URL;
    const [dataInvoice, setDataInvoice] = useState([]);

    const location = useLocation();
    const { nisn, nama, kelas, alamat, setNisn, setNama, setKelas, setAlamat } = useAuthStore();
    const uuid = uuidV4();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const nisnFromQuery = queryParams.get('nisn');
        const namaFromQuery = queryParams.get('nama');
        if (nisnFromQuery) {
            setNisn(nisnFromQuery);
            setNama(namaFromQuery);
        }
    }, [location, setNisn, setNama]);


    useEffect(() => {
      fetchData();
    }, [nisn]);


    const fetchData = async () => {
    try {
        const response = await fetch(`${apiUrl}/v1/laporanPembayaran/getKeuanganSiswa?nisn=${nisn}&kode_bayar=001`);
        if (!response.ok) {
            throw new Error(`Http Error !, Status : ${response.status}`);
        }

        const result = await response.json();
        console.log("result: ", result);
        if (result.status === 'ok' && result.data && Array.isArray(result.data.item)) {
            console.log(result.data.alamat);
            setAlamat(result.data.alamat);
            setKelas(result.data.kelas);
        const formattedItems = result.data.item.map((item) => {
            const formattedEntries = item.entries.map((entry) => ({
                ...entry,
                nominal_bulan: new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                }).format(entry.nominal_bulan)
            }));
    
            return {
                ...item,
                total: new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                }).format(item.total),
                entries: formattedEntries
            };
        });

        const formattedTotal = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(result.data.total);

    
        setDataInvoice({ ...result.data,total: formattedTotal, item: formattedItems });
            console.log("datas: ", dataInvoice);
        } else {
            console.error('Invalid data structure:', result);
            setDataInvoice([])
        }
        } catch (error) {
        console.error()
        }
    }
    const generatePdf = () => {
        const input = document.getElementById('invoice');
    

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('l', 'mm', 'a4');
                const scaleFactor = 1;
                const imgWidth = 297 * scaleFactor;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

                // Check if the height of the image exceeds the height of the page
                if (imgHeight > 210) {
                    let heightLeft = imgHeight;

                    // Loop to add additional pages if needed
                    while (heightLeft >= 0) {
                        position -= 210; // Move to the top of the next page
                        pdf.addPage(); // Add a new page
                        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= 210; // Subtract the height of the page
                    }
                }

                pdf.save('invoice.pdf');

            });
        };

    return(


        <div  className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              {/* Invoice summary */}

              {/* Invoice */}
            <div id="invoice" className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
                <div class="flex items-center justify-between mb-8">
                    <div class="flex flex-col items-center">
                        <img
                            className="mx-auto h-16 w-auto"
                            src={LogoSmk}
                            alt="Logo SMK NU TUlis"
                        />
                        <div class="text-gray-700 font-semibold text-lg mr-2">SMK NU TULIS</div>
                    </div>
                    <div class="text-gray-700">
                        <div class="font-bold text-xl mb-2">Bukti Kurang Bayar</div>
                        <div class="text-sm">
                            <dt className="inline text-gray-500">Tanggal : </dt>{' '}
                            <dd className="inline text-gray-700">
                                <time dateTime={new Date().toISOString().split('T')[0]}>
                                    {new Date().toLocaleDateString('id-ID', { month: 'long', day: 'numeric', year: 'numeric' })}
                                </time>
                            </dd>
                        </div>
                        <div class="text-sm">Invoice #: {nisn}-{uuid}</div>
                    </div>
                </div>
                <h2 className="text-base font-semibold leading-6 text-gray-900">Daftar List Kekurangan</h2>
                <dl className="mt-2 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                    <div className="sm:pr-4">
                        <dt className="inline text-gray-500">Generate Data tanggal : </dt>{' '}
                        <dd className="inline text-gray-700">
                            <time dateTime={new Date().toISOString().split('T')[0]}>
                                {new Date().toLocaleDateString('id-ID', { month: 'long', day: 'numeric', year: 'numeric' })}
                            </time>
                        </dd>
                        <dd className="text-sm font-medium leading-6 text-gray-600">{nama}</dd>
                        <dd className="text-sm font-medium leading-6 text-gray-500">{nisn}</dd>
                        <dd className="text-sm font-medium leading-6 text-gray-500">{kelas}</dd>
                        <dd className="text-sm font-medium leading-6 text-gray-500">{alamat}</dd>
                    </div>
                </dl>
                <table className="mt-4 w-full whitespace-nowrap text-left text-sm leading-6">
                    <colgroup>
                        <col className="w-full" />
                        <col />
                        <col />
                        <col />
                    </colgroup>
                <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                        <th scope="col" className="px-0 py-1 font-semibold">
                            Jenis Pembayaran
                        </th>
                        <th scope="col" className="py-1 pl-8 pr-0 text-right font-semibold">
                            Bulan
                        </th>
                        <th scope="col" className="hidden py-1 pl-8 pr-0 text-right font-semibold sm:table-cell">
                            Nominal
                        </th>
                        <th scope="col" className="py-1 pl-8 pr-0 text-right font-semibold">
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody>

                    {dataInvoice && dataInvoice.item && dataInvoice.item.map((item, index) => (
                        <React.Fragment key={index}>
                            <tr className="border-b border-gray-100">
                                <td className="px-0 py-1 font-semibold text-gray-900">{item.title}</td>
                                <td className="py-1 pl-8 pr-0 text-right font-semibold"></td>
                                <td className="hidden py-1 pl-8 pr-0 text-right font-semibold sm:table-cell"></td>
                                <td className="hidden py-1 pl-8 pr-0 text-right font-semibold sm:table-cell">{item.total}</td>
                            </tr>
                            {item.title === 'spp' && item.entries && item.entries.map((entry, entryIndex) => (
                                <tr key={`${index}-${entryIndex}`} className="border-b border-gray-100 border-opacity-0">
                                    <td className="px-0 py-1 ml-8">{entry.title}</td>
                                    <td className="py-1 pl-10 pr-0 text-right text-sm font-sans">{entry.bulan}</td>
                                    <td className="hidden py-1 pl-8 pr-0 text-right sm:table-cell text-sm">{entry.nominal_bulan}</td>
                                    <td className="py-1 pl-8 pr-0 text-right"></td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}


                </tbody>
                    <tfoot>
                        <tr>
                        <th scope="row" className="pt-4 font-semibold text-gray-900 sm:hidden">
                            Total
                        </th>
                        <th
                            scope="row"
                            colSpan={3}
                            className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                        >
                            Total
                        </th>
                        <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                            {dataInvoice.total}
                        </td>
                        </tr>
                    </tfoot>
                </table>
                </div>
                    <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                        <a href="#" onClick={(e) => {e.preventDefault();generatePdf(); }} className="text-sm font-semibold leading-6 text-lime-700">
                        Download Kekurangan <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
            </div>
        </div>
    )
}

export default DataKeuanganSiswa;