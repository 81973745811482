import Card from "components/card";
import AlertSuccess from "components/notification/alertNotificationSuccess";
import { useState } from "react";
import { Link } from "react-router-dom";
import image1 from "../../../assets/img/tablekeuangan/image1.png";
import image2 from "../../../assets/img/tablekeuangan/image2.png";
import image3 from "../../../assets/img/tablekeuangan/image3.png";


const Keuangan = () => {

    const [openPopUp, setOpenPopUp] = useState(false);
    // const HandleRemovePopUp = () => setOpenPopUp(false);


    return(
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white">
            <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
                <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
                    {/* project 1 */}
                    <Card extra={"w-full p-4 h-full"}>
                        <div className="mb-8 w-full">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Daftar Keuangan Sekolah
                            </h4>
                            <p className="mt-2 text-base text-gray-600">
                                Pemasukan
                            </p>
                        </div>
                        {/* Project 1 */}
                        <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <div className="flex items-center">
                                <div className="">
                                <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="Maks Pemasukan SPP" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                    Table Transaksi SPP
                                </p>
                                <p className="mt-2 text-sm text-gray-600">
                                    Silahkan klik button di bawah untuk melihat detail table
                                </p>
                                <Link to="/table-spp">
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 mt-1 rounded-md bg-lime-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-400 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible: outline-lime-800">
                                        Detail Table
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* project 2 */}
                        <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl mt-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <div className="flex items-center">
                                <div className="">
                                <img className="h-[83px] w-[83px] rounded-lg" src={image2} alt="Maks Pemasukan SPP" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                    Table Transaksi Praktikum
                                </p>
                                <p className="mt-2 text-sm text-gray-600">
                                    Silahkan klik button di bawah untuk melihat detail table
                                </p>
                                <Link to="/table-pembayaran-praktikum">
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 mt-1 rounded-md bg-lime-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-400 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible: outline-lime-800">
                                        Detail Table
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* project 3 */}
                        <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl mt-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <div className="flex items-center">
                                <div className="">
                                <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="Maks Pemasukan SPP" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                    Table Transaksi PPDB
                                </p>
                                <p className="mt-2 text-sm text-gray-600">
                                    Silahkan klik button di bawah untuk melihat detail table
                                </p>
                            </div>
                        </div>
                        {/* project 4 */}
                        <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl mt-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <div className="flex items-center">
                                <div className="">
                                <img className="h-[83px] w-[83px] rounded-lg" src={image3} alt="Laporan Pemasukan Bulanan" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                    Laporan Bulanan
                                </p>
                                <p className="mt-2 text-sm text-gray-600">
                                    Silahkan klik button di bawah untuk melihat detail table
                                </p>
                            </div>
                        </div>
                    </Card>
                </div>
                {/* project 2 */}
                <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
                    {/* project 1 */}
                    <Card extra={"w-full p-4 h-full"}>
                        <div className="mb-8 w-full">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Data Administrasi Kekurangan Siswa
                            </h4>
                            <p className="mt-2 text-base text-gray-600">
                                Data kekurangan pembayaran siswa
                            </p>
                        </div>
                        {/* Project 1 */}
                        <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <div className="flex items-center">
                                <div className="">
                                <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="Maks Pemasukan SPP" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                    Data Kekurangan Siswa Per Kelas
                                </p>
                                <p className="mt-2 text-sm text-gray-600">
                                    Silahkan klik button di bawah untuk melihat detail table
                                </p>
                                <Link to="/kurang-bayar-siswa">
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 mt-1 rounded-md bg-lime-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-400 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible: outline-lime-800">
                                        Detail Table
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* project 2 */}
                        <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 mt-2 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <div className="flex items-center">
                                <div className="">
                                <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="Maks Pemasukan SPP" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <p className="text-base font-medium text-navy-700 dark:text-white">
                                    Table Transaksi
                                </p>
                                <p className="mt-2 text-sm text-gray-600">
                                    Silahkan klik button di bawah untuk melihat detail table
                                </p>
                                    {/* <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 mt-1 rounded-md bg-lime-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-400 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible: outline-lime-800"
                                        onClick={() => setOpenPopUp(true)}
                                        >
                                        Show pop up
                                    </button> */}
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-1.5 mt-1 rounded-md bg-lime-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-400 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible: outline-lime-800"
                                        // onClick={() => setOpenPopUp(true)}
                                        onClick={() => {
                                            window.open(`/#/cetak-bulanan?nama=${encodeURIComponent("Ahmad")}`,'_blank', 'noopener,noreferrer');
                                        }}
                                        >
                                        Download kwitansi
                                    </button>

                                    
                                
                            </div>
                            
                        </div>
                    </Card>
                
                </div>
            </div>
            {/* <NotifSuccessPay open={openPopUp} onClose={() => setOpenPopUp(false)}>
                <div className="text-center w-56">
                    <Download size={56} className="mx-auto text-lime-700" />
                    <div className="mx-auto my-4 w-48">
                        <h3 className="text-lg font-black text-gray-800">
                            Confirm Cetak
                        </h3>
                        <p className="text-sm text-gray-500">
                            Silahkan klik download untuk bisa cetak kwitansi
                        </p>
                    </div>
                    <div className="flex gap-4">
                        <button className="btn btn-danger w-full">
                            batal
                        </button>
                        <button className="btn btn-light w-full" onClick={() => setOpenPopUp(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </NotifSuccessPay> */}
           <AlertSuccess open={openPopUp} onClose={() => setOpenPopUp(false)}>
                <div>
                <h3 className="text-sm font-medium text-green-800">Pembayaran Berhasil !!</h3>
                    <div className="mt-2 text-sm text-green-700">
                        <p>Pembayaran dengan jenis transaksi : , dengan jumlah : , telah berhasil</p>
                    </div>
                </div>
            </AlertSuccess>
            
            
        </div>
    )
}

export default Keuangan;