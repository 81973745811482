import image4 from "assets/img/profile/School1.png";
import image5 from "assets/img/profile/diary-book.png";
import image1 from "assets/img/profile/petugasPenarikan.png";
import Card from "components/card";
import { MdModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";

const Project = () => {
  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Config Khusus Staff Admin
        </h4>
        <p className="mt-2 text-base text-gray-600">
          Silahkan untuk melengkapi dan juga mengedit data yang berkaitan dengan staff Administrasi
        </p>
      </div>
      {/* Project 1 */}
      <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl mt-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image4} alt="" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white">
              Denom Configuration
            </p>
            <p className="-mt-0.5 text-sm text-gray-600">
              Configurasi data denom/nominal jumlah Iuran SPP tiap Kelas
            </p>
            <Link to="/denom-config">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 mt-1 rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible: outline-indigo-600"
                >Denom Config</button>
              </Link>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>

      {/* Project 2 */}
      <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 mt-2 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="petugas penarikan" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white">
              Petugas Penarikan Configuration
            </p>
            <p className="-mt-0.5 text-sm text-gray-600">
              Configurasi untuk menambahkan petugas penarikan
            </p>
            <Link to="/petugas-penarikan">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 mt-1 rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible: outline-indigo-600"
                >Petugas Penarikan</button>
              </Link>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white">
          <MdModeEditOutline />
        </div>
      </div>

      {/* Project 3 */}
      <div className="flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 mt-2 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex items-center">
          <div className="">
            <img className="h-[83px] w-[83px] rounded-lg" src={image5} alt="Maks Pemasukan SPP" />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-navy-700 dark:text-white">
              Config Nilai Maks Pemasukan Kelas X
            </p>
            <p className="-mt-0.5 text-sm text-gray-600">
              Configurasi untuk memberikan nilai maksimal pemasukan kelas X
            </p>
            <Link to="/config-maks-pemasukan">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 mt-1 rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible: outline-indigo-600"
                >
                  Config Nilai Maks
                </button>
            </Link>
          </div>
        </div>
      </div>
      
    </Card>
  );
};

export default Project;
