import { ChevronDownIcon } from '@heroicons/react/20/solid';
import NotifSuccessRegisGUru from 'components/notification/notifSuccessRegisGuru';
import { useState } from 'react';
import DataPicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

const RegistrationGuru = () => {
  const [data, setData] = useState({
    nama:'',
    alamat:'',
    tahun_masuk:'',
    email:'',
    created_at: new Date(),
    no_hp:'',
    nama_role:'',
    id_role:'',
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  const [showNotification, setShowNotification] = useState(false);

  const [startDate, setStartDate] = useState(new Date());

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${apiUrl}/v1/guru/signUpGuru`, {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if(!response.ok){
        throw new Error('Network response was not oke');
      }else{
        return response.json();
      }
    })
    .then((data) => {
      console.log('Success:', data);
      //clear data after succes hit
      setData({
        nama:'',
        alamat:'',
        tahun_masuk:'',
        email:'',
        created_at: '',
        no_hp:'',
        nama_role:'',
        id_role:'',
      });

      // Show success notification
        setShowNotification(true);

        // Hide notification after a few seconds (adjust as needed)
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);

    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const toggleModal = () => {
    setShowNotification(!showNotification);
};

  //handling get role name from value role id
  const handleChangeRole = (e) => {
    const selectedRoleId = parseInt(e.target.value, 10);
    const selectedRoleName = e.target.options[e.target.selectedIndex].text;

    setData((prevData) => ({
      ...prevData,
      id_role: selectedRoleId,
      nama_role: selectedRoleName,
    }));
  };
  

  const handleChangeDate = (date) => {
    // 'date' parameter will be the selected date
    setStartDate(date);

    setData({ ...data, tahun_masuk: date }); // Assuming you want to store it in 'tanggal_lahir'
  };

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Form Registration Guru</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Masukkan data guru ke dalam form berikut ini
        </p>
      </div>
      <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label htmlFor="nama" className="block text-sm font-semibold leading-6 text-gray-900">
              Nama Guru
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="nama"
                id="nama"
                value={data.nama}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="alamat" className="block text-sm font-semibold leading-6 text-gray-900">
              Alamat
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="alamat"
                id="alamat"
                value={data.alamat}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2 flex items-center">
            <label htmlFor="tahun_masuk" className="block max-w-none text-left mr-16 font-bold sm:text-sm leading-6 text-gray-900">
                Tahun Masuk
            </label>
            <div className="mt-2.5">
                <DataPicker
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    selected={startDate}
                    onChange={handleChangeDate}
                />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                id="email"
                value={data.email}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="no_hp" className="block text-sm font-semibold leading-6 text-gray-900">
              Nomor Handphone
            </label>
            <div className="relative mt-2.5">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="negara" className="sr-only">
                  Negara
                </label>
                <select
                  id="negara"
                  name="negara"
                  className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm"
                >
                  <option>ID</option>
                </select>
                <ChevronDownIcon
                  className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="no_hp"
                id="no_hp"
                value={data.no_hp}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
              <label htmlFor="id_role" className="block text-sm font-semibold leading-6 text-gray-900">
                Pilih Jabatan
              </label>
              <div className="relative mt-2.5">
                <select
                  id="id_role"
                  name="id_role"
                  value={data.id_role}
                  onChange={handleChangeRole}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value={1}>Guru</option>
                  <option value={2}>Bendahara Sekolah</option>
                  <option value={3}>Bendahara Bos</option>
                  <option value={4}>Kepala Sekolah</option>
                  <option value={5}>Admin</option>
                </select>
              </div>
            </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-lime-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
          >
            Register Guru
          </button>
          {showNotification && (
                                    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-70 z-50">
                                        <NotifSuccessRegisGUru onClose={toggleModal} />
                                    </div>
                            )}
        </div>
      </form>
      {/* {showNotification && (<NotifSuccess />)} */}
    </div>
  )
}

export default RegistrationGuru;
