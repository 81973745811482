import SidebarCard from "components/sidebar/components/SidebarCard";
import { HiX } from "react-icons/hi";
import routes from "routes.js";
import LogoSekolah from "../../assets/img/login/logosmk.png";
import Links from "./components/Links";

const Sidebar = ({ open, onClose}) => {

    return(
        <div className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"}`}>
            <span className="absolute top-4 right-4 block cursor-pointer xl:hidden" onClick={onClose} >
                <HiX />
            </span>
        <div className="mx-[56px] mt-[50px] flex items-center">
            <img src={LogoSekolah} alt="Logo Sekolah" className="w-20 h-20 mr-1" />
            <div className="flex flex-col justify-center">
                <div className="font-poppins text-2xl font-bold text-navy-700 dark:text-white">
                    SMK NU
                </div>
                <div className="font-poppins text-xl text-center font-medium text-navy-700 dark:text-white">
                    TULIS
                </div>
            </div>
        </div>

        <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
            {/* Nav item */}
            <ul className="mb-auto pt-1">
                <Links routes={routes}/>
            </ul>
            
            {/* Free Horizon Card */}
            <div className="flex justify-center">
                <SidebarCard />
            </div>

            {/* Nav item end */}
        </div>
    );
};

export default Sidebar;