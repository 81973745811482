import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React, { useEffect, useState } from "react";

const  KurangBayarSiswa = () => {
    const [open, setOpen] = React.useState(true);

    const apiUrl = process.env.REACT_APP_API_URL;

    const [dataKurangBayar, setDataKurangBayar] = useState([]);
    const [kelas, setKelas] = useState([]);

    // api 2
    const [allKelas, setAllKelas] = useState([]);

    useEffect(() => {
        const fetchAllKelas = async () => {
            try {
                const responseAllKelas = await fetch(`${apiUrl}/v1/rombel/getAllKelas`);

                if (!responseAllKelas.ok){
                    throw new Error(`HTTP Error! status: ${responseAllKelas.status}`);
                }

                const result = await responseAllKelas.json();
                console.log('Success : ', result);

                if (result.status === 'ok' && result.data && Array.isArray(result.data)) {
                    console.log('result data all kelas: ', result.data);
                    setAllKelas(result.data);
                }
            } catch (error) {
                console.error('Error at get all kelas', error);
                setAllKelas([]);
            }
        };

        fetchAllKelas();
    }, []);

    // api 1
    useEffect(() => {
        fetchDataKekurangan();
    }, [kelas]);

    const fetchDataKekurangan = async () => {
        const queryString = Object.keys(kelas)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(kelas[key])}`)
            .join('&');
        console.log(allKelas.rombel_saat_ini);

        console.log(queryString);

        try {
            const responseData = await fetch(`${apiUrl}/v1/laporanPembayaran/getKekuranganAll?${queryString}`);

            if(!responseData.ok){
                throw new Error(`HTTP Error! status: ${responseData.status}`);
            }

            const result = await responseData.json();
            console.log('Success Get Data Kekurangan: ', result);

            if(result.status === 'ok' && result.data && Array.isArray(result.data)){
                const formattedCurrencys = result.data.map((person) => {
                    const formattedCurrency = new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR'
                    }).format(person.kekurangan_spp);
                    
                    const formattedCurrencyPraktikum = new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR'
                    }).format(person.kekurangan_praktikum);

                    const formattedCurrencyPpdb = new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR'
                    }).format(person.kekurangan_ppdb);

                    return {
                        ...person,
                        formattedCurrency,
                        formattedCurrencyPraktikum,
                        formattedCurrencyPpdb
                    };
                });
                setDataKurangBayar(formattedCurrencys);
                console.log(formattedCurrencys);
            }else{
                console.error('Invalid data structure: ', result);
                setDataKurangBayar([]);
            }

        } catch (error) {
            console.error('Error', error);
            setDataKurangBayar([]);
        }
    };

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setKelas({
            ...kelas,
            [name]: value,
        });
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    return(
        <>
        <div className="flex h-full w-full">
            <Sidebar open ={open} onClose={() => setOpen(false)} />
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                <main className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}>
                    <div className="h-full">
                        <Navbar />
                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white">
                        {/* content area*/}
                            <div className="px-4 sm:px-6 lg:px-8">
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Data Kekurangan Bayar Siswa</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        Pilih Kelas yang akan dilihat
                                        {/* <strong className="font-semibold text-gray-900">Startup</strong> plan. The next payment
                                        of $80 will be due on August 4, 2022. */}
                                    </p>
                                    </div>
                                </div>
                                {/* form input data kelas */}
                                <form onSubmit={(e) => {fetchDataKekurangan();}}>
                                    <div>
                                        <div className="sm:flex sm:items-center">
                                            <div className="flex items-center">
                                                <label htmlFor="kelas" className="text-base font-semibold leading-6 text-gray-900 mr-3">
                                                    Kelas
                                                </label>
                                                <div className="relative mt-2.5">
                                                    <select
                                                        id="kelas"
                                                        name="kelas"
                                                        value={kelas.kelas}
                                                        onChange={handleChange}
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value="">Select kelas</option>
                                                        {allKelas.map((kelas, index) => (
                                                            <option key={index} value={kelas.rombel_saat_ini}>
                                                                {kelas.rombel_saat_ini}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* end form */}

                                <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Nama
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                        >
                                            Kekurangan SPP
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                        >
                                            Kekurangan Praktikum
                                        </th>
                                        <th
                                            scope="col"
                                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                        >
                                            Kekurangan PPDB
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Detail</span>
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {dataKurangBayar.map((plan, planIdx) => (
                                            <tr key={plan.id} className={plan.kekurangan_spp === '0' && plan.kekurangan_praktikum ==='0'? 'bg-lime-300 text-white' : ''}>
                                                <td
                                                    className={classNames(
                                                        planIdx === 0 ? '' : 'border-t border-transparent',
                                                        'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                                                    )}
                                                >
                                                    <div className="font-medium text-gray-900">
                                                        {plan.nama}
                                                        {plan.isCurrent ? <span className="ml-1 text-indigo-600">(Current Plan)</span> : null}
                                                    </div>
                                                    <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                                                        <span>{plan.memory} / {plan.cpu}</span>
                                                        <span className="hidden sm:inline">·</span>
                                                        <span>{plan.storage}</span>
                                                    </div>
                                                    {planIdx !== 0 ? <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" /> : null}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        planIdx === 0 ? '' : 'border-t border-gray-200',
                                                        ' px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                                                    )}
                                                >
                                                    {plan.formattedCurrency}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        planIdx === 0 ? '' : 'border-t border-gray-200',
                                                        'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                                                    )}
                                                >
                                                    {plan.formattedCurrencyPraktikum}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        planIdx === 0 ? '' : 'border-t border-gray-200',
                                                        'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                                                    )}
                                                >
                                                    {plan.formattedCurrencyPpdb}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        planIdx === 0 ? '' : 'border-t border-transparent',
                                                        'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
                                                    )}
                                                >
                                                    {plan.nisn && (
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                                            disabled={plan.isCurrent}
                                                            onClick={() => {
                                                                window.open(`/#/data-keuangan-siswa?nama=${encodeURIComponent(plan.nama)}&nisn=${encodeURIComponent(plan.nisn)}`, '_blank', 'noopener,noreferrer');
                                                            }}
                                                        >
                                                            Detail<span className="sr-only">, {plan.nama}</span>
                                                        </button>
                                                    )}
                                                    {planIdx !== 0 ? <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" /> : null}
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        </>
        



        
    )
}

export default KurangBayarSiswa;