import { create } from 'zustand';

const useAuthStore = create((set) => ({
    isLoggedIn: false,
    role: '',
    roleName: '',
    email: '',
    authorizationToken: '',
    nama: '',
    nisn: '',
    setLoginData: (loginData) => set(loginData),
    logout: () => set({ isLoggedIn: false, role: '', roleName: '', email: '', authorizationToken: '', nama: '', nisn: '' }),
    kelas: '',
    alamat: '',
    setNisn: (nisn) => set((state) => ({ ...state, nisn })),
    setNama: (nama) => set((state) => ({ ...state, nama })),
    setKelas: (kelas) => set((state) => ({ ...state, kelas })),
    setAlamat: (alamat) => set((state) => ({ ...state, alamat })),
}));

export default useAuthStore;