
import logoAvatar from "assets/img/avatars/logosmk.png";
import banner from "assets/img/profile/banner.png";
import useAuthStore from "authStore";
import Card from "components/card";
import { useEffect, useState } from "react";
const Banner = () => {
  const [namaProfile, setNamaProfile] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL; // Update with your actual API URL
  const nama = useAuthStore((state) => state.nama);
  const nisn = useAuthStore((state) => state.nisn);

  useEffect(() => {
    const fetchProfileData = async () => {
      console.log(nama);
      console.log(nisn);

      try {
        const response = await fetch(`${apiUrl}/v1/rombel/getListDataProfile?nama=${nama}&nisn=${nisn}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Success:', result);

        if (result.status === 'ok' && result.data && Array.isArray(result.data)) {
          setNamaProfile(result.data);
        } else {
          console.error('Invalid data structure:', result);
          setNamaProfile([]);
        }
      } catch (error) {
        console.error('Error:', error);
        setNamaProfile([]);
      }
    };

    fetchProfileData();
  },[apiUrl]); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount

  


  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={logoAvatar} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <form onSubmit={''} >
        {namaProfile.map((data) => (
          <div className="mt-16 flex flex-col items-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {data.nama}
          </h4>
          <p className="text-base font-normal text-gray-600">{data.rombel_saat_ini}</p>
        </div>
        ))}
      </form>
    </Card>
  );
};

export default Banner;
