import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoSmk from "../../assets/img/login/logosmk.png";


const SignUp = () => {
    const [data, setData] = useState({
        nama_siswa:'',
        username:'',
        email:'',
        password:'',
        role:'1',
        nisn:'',
    });


    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(`${apiUrl}/v1/login/create`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response) => {
            if(!response.ok){
                throw new Error('Network response was not oke');
            }else{
                return response.json();
            }
        })
        .then((data) => {
            if (data.status === 'ok'){
                navigate("/login");
                window.location.reload();
                console.log("masuk ke halaman login")
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value || undefined });
    };

    // hit api to get all kelas
    const [allKelas, setAllKelas] = useState([]);
    
    useEffect(() => {
        const fetchAllKelas = async () => {
            try {
                const responseAllKelas = await fetch(`${apiUrl}/v1/rombel/getAllKelas`);

                if (!responseAllKelas.ok){
                    throw new Error(`HTTP Error! status: ${responseAllKelas.status}`);
                }

                const result = await responseAllKelas.json();
                console.log('Success : ', result);

                if (result.status === 'ok' && result.data && Array.isArray(result.data)) {
                    setAllKelas(result.data);
                }
            } catch (error) {
                console.error('Error at get all kelas', error);
                setAllKelas([]);
            }
        };

        fetchAllKelas();
    }, []);

    // hit api to get data kelas with nama and nisn

    useEffect(() => {
        fetchAllDataKelas();
    }, [data.kelas]);

    const [dataAllKelas, setDataAllKelas] = useState([])

    const fetchAllDataKelas = () => {
        fetch(`${apiUrl}/v1/rombel/getListRombel?rombel_saat_ini=${data.kelas}`)
        .then((response) => {
            if(!response.ok){
                throw new Error('Network response was not oke');
            }
            return response.json();
        })
        .then((result) => {
            console.log('Success:', result);
            if(result.status === 'ok' && result.data && Array.isArray(result.data)){
                setDataAllKelas(result.data);
            }else{
                console.error('Invalid data structure:', result);
                setDataAllKelas([]);
            }
        })
        .catch((error)=> {
            console.error('Error:', error);
            setDataAllKelas([]);
        })
    }

    const handleNameSelect = (selectedName) => {
        // Find the corresponding student by name and set the nisn in the state
        const selectedStudent = dataAllKelas.find((student) => student.nama === selectedName);
        if (selectedStudent) {
            const selectedNisn = selectedStudent.nisn;

            const newData = Object.fromEntries(
                Object.entries(data).filter(([key]) => key !== 'kelas')
              );

            setData({ ...newData,
                nama_siswa: selectedName,
                nisn: selectedNisn || undefined });
        }
    };

    return(
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-24 w-auto"
                    src={LogoSmk}
                    alt="Logo SMK NU TUlis"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                   Create Account
                </h2>
            </div>
            {/* form */}
            <div className="bg-white p-10 md:w-3/4 lg:w-1/2 mx-auto">
                <form onSubmit={handleSubmit}>

                    <div className="sm:col-span-2">
                        <label htmlFor="kelas" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                            Kelas
                        </label>
                        <div className="mt-2">
                            <select
                                id="kelas"
                                name="kelas"
                                value={data.kelas}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                                <option value="">Select kelas</option>
                                {allKelas.map((kelas, index) => (
                                    <option key={index} value={kelas.rombel_saat_ini}>
                                        {kelas.rombel_saat_ini}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                            <label htmlFor="studentName" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                Nama Siswa
                            </label>
                            <div className="mt-2">
                                <select
                                    id="studentName"
                                    name="nama_siswa"
                                    value={data.nama_siswa}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleNameSelect(e.target.value);
                                    }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    >
                                    <option value="">Nama Siswa</option>
                                        {dataAllKelas.map((student, index) => (
                                    <option key={index} value={student.nama}>
                                        {student.nama}
                                    </option>
                                    ))}
                                </select>
                            </div>
                    </div>

                    <div className="sm:col-span-2">
                            <label htmlFor="nisn" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                NISN
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="number"
                                    name="nisn"
                                    id="nisn"
                                    value={data.nisn}
                                    onChange={handleChange}
                                    placeholder="masukkan NISN"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                />
                            </div>
                    </div>

                    {/* <div className="flex items-center mb-5">
                        <label htmlFor="nama" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Nama Lengkap
                        </label>
                        <input 
                            type="text" 
                            id="nama"
                            name="nama"
                            value={data.nama}
                            onChange={handleChange}
                            placeholder="masukkan nama lengkap"
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div> */}

                    <div className="flex items-center mb-5">
                        <label htmlFor="username" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <input 
                            type="text" 
                            id="username"
                            name="username"
                            value={data.username}
                            onChange={handleChange}
                            placeholder="username untuk login"
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>

                    <div className="flex items-center mb-5">
                        <label htmlFor="email" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Email
                        </label>
                        <input 
                            type="email" 
                            id="email"
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                            placeholder="masukkan email terdaftar"
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>

                    <div className="flex items-center mb-5">
                        <label htmlFor="password" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Password
                        </label>
                        <input 
                            type="password" 
                            id="password"
                            name="password"
                            value={data.password}
                            onChange={handleChange}
                            placeholder="masukkan password minimal 5 digit"
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>

                    <div className="flex items-center mb-5">
                        <label htmlFor="role" className="block w-28 mr-6 text-left font-medium leading-6 text-gray-900">
                            Select Role
                        </label>
                        <select 
                            className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleChange}
                            value={data.role}
                            name="role"
                        >
                            <option value="1">Siswa</option>
                            <option value="2">Guru</option>
                            <option value="3">Bendahara Sekolah</option>
                            <option value="4">Bendahara Bos</option>
                            <option value="5">Kepala Sekolah</option>
                            <option value="6">Admin</option>
                        </select>
                    </div>

                    <div className="text-right">
                        <button
                            type="submit"
                            className="flex justify-center rounded-md bg-indigo-600 px-8 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                Create Account
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default SignUp;