import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import routes from "routes.js";
export default function Admin(props){
    const {...rest}  = props;
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

    React.useEffect(() => {
        window.addEventListener("resize", () =>
          window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
        );
      }, []);
      React.useEffect(() => {
        getActiveRoute(routes);
      }, [location.pathname]);
    
      const getActiveRoute = (routes) => {
        let activeRoute = "Main Dashboard";
        for (let i = 0; i < routes.length; i++) {
          if (
            window.location.href.indexOf(
              routes[i].layout + "/" + routes[i].path
            ) !== -1
          ) {
            setCurrentRoute(routes[i].name);
          }
        }
        return activeRoute;
      };

      const getRoutes = (routes) => {
        return routes.map((prop, key) => {
          if (prop.layout === "/admin") {
            return (
              <Route
                path={`/${prop.path}`}
                element={prop.component}
                key={key} />
            );
          } else {
            return null;
          }
        });
      };


      const getActiveNavbar = (routes) => {
        return routes.map((prop, key) => {
          if (prop.subRoutes) {
            return (
              <div key={key} className="relative inline-block text-left">
                <div>
                  <span className="inline-flex rounded-md">
                    {prop.icon}
                  </span>
                </div>
                <div>
                  {prop.name}
                  <div>
                    {prop.subRoutes.map((subRoute, subKey) => (
                      <div key={subKey}>
                      <Link to={subRoute.layout + "/" + subRoute.path}>{subRoute.name}</Link>
                    </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          }else{
            return (
              <div key={key} className="relative inline-block text-left">
                <div>
                  <span className="inline-flex rounded-md">
                    {prop.icon}
                  </span>
                </div>
                <div>
                  <Link to={prop.layout + "/" + prop.path}>{prop.name}</Link>
                </div>
              </div>
            );
          }
        })
      }
      document.documentElement.dir = "ltr";
    return(
        <div className="flex h-full w-full">
            <Sidebar open={open} onClose={() => setOpen(false)} />
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                <main className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}>
                    {/* Routes */}
                    <div className="h-full">
                        <Navbar
                        onOpenSidenav={() => setOpen(true)}
                        logoText={"SMK NU TULIS"}
                        brandText={currentRoute}
                        secondary={getActiveNavbar(routes)}
                        {...rest}
                        />
                        <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
                          {/* render main routes */}
                            <Routes>
                                {getRoutes(routes)}
                                <Route path="/" element={<Navigate to="/admin/default" replace />}/>
                            </Routes>
                        </div>
                        <div className="p-3">
                        {/* <Footer /> */}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
