
import Widget from "components/widget/Widget";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import DataKasSekolah from "./components/DataKasSekolah";

import useAuthStore from "authStore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PieChartCard from "./components/PieChartCard";
import { columnsDataCheck } from "./variables/columnsData";
import tableDataCheck from "./variables/tableDataCheck.json";

const Dashboard = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();

    const [nominalMasuk, setNominalMasuk] = useState(null);
    const [nominalMasukDanaBos, setNominalMasukDanaBos] = useState(null);

    const now = new Date();
    const bulanNames = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
    const bulan = bulanNames[now.getMonth()];

    // const isLogin = localStorage.getItem('islogin');
    const isLogin = useAuthStore((state) => state.isLoggedIn);
    console.log('login zustand: ', isLogin);
useEffect(() => {
    fetchDataNominalMasuk();
}, []); // Add an empty dependency array to run the effect only once on mount


const fetchDataNominalMasuk = async () => {
    try {
        const response = await fetch(`${apiUrl}/v1/riwayatPembayaran/sumPemasukan?bulan=${bulan}`);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Success:', result);

        if (result.status === 'ok' && result.data && Array.isArray(result.data)) {
            const formattedNominal = result.data.map((nominal) => {
                const nominalSPP = new Intl.NumberFormat('id-ID',{
                    style: 'currency',
                    currency: 'IDR'
                }).format(nominal.SPP);

                const nominalPPDB = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR'
                }).format(nominal.PPDB);

                const nominalPraktikum = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR'
                }).format(nominal.PRAKTIKUM);

                return {
                    ...nominal,
                    nominalSPP,
                    nominalPPDB,
                    nominalPraktikum,
                };
            });
            console.log(formattedNominal);
            setNominalMasuk(formattedNominal);
        }
    } catch (error) {
        console.error('Invalid get data from table');
        setNominalMasuk();
    }
}

if( isLogin ){
    return (
        <div>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
                <Widget
                    icon={<MdBarChart className="h-7 w-7"/>}
                    title={"Pemasukan Bulan Ini SPP"}
                    nominal={nominalMasuk !== null ? `${nominalMasuk[0].nominalSPP},-` : (
                        <div className="flex items-center">
                            <div className="w-4 h-4 mr-2 border-t-2 border-lime-500 rounded-full animate-spin"></div>
                            <span>Belum ada pemasukan ...</span>
                        </div>
                    )}
                />
                <Widget
                    icon={<IoDocuments className="h-6 w-6" />}
                    title={"Pemasukan Bulan Ini Praktikum"}
                    nominal={nominalMasuk !== null ? `${nominalMasuk[0].nominalPraktikum !== null ? nominalMasuk[0].nominalPraktikum : '0'},-` : (
                        <div className="flex items-center">
                            <div className="w-4 h-4 mr-2 border-t-2 border-lime-500 rounded-full animate-spin"></div>
                            <span>Belum ada pemasukan ...</span>
                        </div>
                    )}
                />
                <Widget
                    icon={<MdBarChart className="h-7 w-7" />}
                    title={"Pemasukan Bulan Ini PPDB"}
                    nominal={nominalMasuk !== null ? `${nominalMasuk[0].nominalPPDB !== null ? nominalMasuk[0].nominalPPDB : '0'},-` : (
                        <div className="flex items-center">
                            <div className="w-4 h-4 mr-2 border-t-2 border-lime-500 rounded-full animate-spin"></div>
                            <span>Belum ada pemasukan ...</span>
                        </div>
                    )}
                />
                <Widget
                    icon={<MdDashboard className="h-6 w-6" />}
                    title={"Dana Bos"}
                    nominal={nominalMasukDanaBos !== null ? `${nominalMasuk[0].nominal !== null ? nominalMasuk[0].nominalPPDB : '0'},-` : (
                        <div className="flex items-center">
                            <div className="w-4 h-4 mr-2 border-t-2 border-lime-500 rounded-full animate-spin"></div>
                            <span>Belum ada perhitungan ...</span>
                        </div>
                    )}
                    
                />
                <Widget
                    icon={<MdBarChart className="h-7 w-7" />}
                    title={"Pengeluaran Bos"}
                    nominal={nominalMasukDanaBos !== null ? `${nominalMasuk[0].nominal !== null ? nominalMasuk[0].nominalPPDB : '0'},-` : (
                        <div className="flex items-center">
                            <div className="w-4 h-4 mr-2 border-t-2 border-lime-500 rounded-full animate-spin"></div>
                            <span>Belum ada perhitungan ...</span>
                        </div>
                    )}
                />
                <Widget
                    icon={<IoMdHome className="h-6 w-6" />}
                    title={"Sisa Keseluruhan"}
                    nominal={nominalMasukDanaBos !== null ? `${nominalMasuk[0].nominal !== null ? nominalMasuk[0].nominalPPDB : '0'},-` : (
                        <div className="flex items-center">
                            <div className="w-4 h-4 mr-2 border-t-2 border-lime-500 rounded-full animate-spin"></div>
                            <span>Belum ada perhitungan ...</span>
                        </div>
                    )}
                />
            </div>
            {/* Check Table */}
            {/* <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
                <div>
                    <CheckTable
                        columnsData={columnsDataCheck}
                        tableData={tableDataCheck}
                    />
                </div>
            </div> */}

            {/* Pie Chart */}
            <div className="mt-5 grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
                <div>
                    <PieChartCard />
                </div>
                
            </div>

            {/* Data Kas Sekolah */}
            <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
                <div>
                    <DataKasSekolah
                        columnsData={columnsDataCheck}
                        tableData={tableDataCheck}
                    />
                </div>
            </div>
            
        </div>
    )
}else{
    navigate("/login");
}

    
}

export default Dashboard;