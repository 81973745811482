
const GlobalNotif = () => {

    return(
        <div className='fixed inset-0 bg-black flex justify-center items-center bg-opacity-20 backdrop-blur-sm'>
            <div className='p-2 bg-white w-10/12 md:w-1/2 lg:1/3 shadow-inner border-e-red-50 rounded-lg py-5'>
                <div className="w-full p-3 flex justify-center items-center">
                    <svg className="animate-spin h-5 w-5 mr-3 text-lime-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 4.418 3.582 8 8 8v-4c-2.01 0-3.847-.778-5.229-2.042l1.729-2.977zm16-2.582A7.963 7.963 0 0120 12h4c0-6.627-5.373-12-12-12v4c2.017 0 3.862.785 5.259 2.066l-1.729 2.977z"></path>
                    </svg>
                    <span className="text-lime-600">Processing...</span>
                </div>
            </div>
        </div>
    )
}

export default GlobalNotif;