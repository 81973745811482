import Banner from "./components/Banner";
import DetailKekurangan from "./components/DetailKekurangan";
import DetailKekuranganPpdb from "./components/DetailKekuranganPpdb";
import General from "./components/General";
import DetailRiwayatBarar from "./components/RiwayatPembayaran";

const ProfileOverview = () => {
  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          <Banner />
        </div>

        {/* <div className="col-span-3 lg:!mb-0">
          <Storage />
        </div>

        <div className="z-0 col-span-5 lg:!mb-0">
          <Upload />
        </div> */}
      </div>
      {/* all project & ... */}
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <General />
          {/* <RiwayatPembayaran
            columnsData={columnsDataComplex}
            tableData={TableDataComplex}
          /> */}
          <DetailRiwayatBarar />
          <DetailKekurangan />
          <DetailKekuranganPpdb />
        </div>
      </div>
  );
};

export default ProfileOverview;
