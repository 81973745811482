import useAuthStore from "authStore";
import Card from "components/card";
import { useEffect, useState } from "react";

const DetailKekuranganPpdb = () => {

     //initiate url
    const apiUrl = process.env.REACT_APP_API_URL;
     //initiate useState
    const [dataKekurangan, setDataKekurangan] = useState([]);
    const nisn = useAuthStore((state) => state.nisn);
    useEffect(() => {
        const fetchDataKekurangan = async () => {
            const kode_bayar = '003';
            try {
                const responseData = await fetch(`${apiUrl}/v1/laporanPembayaran/getDataListKurangBayar?nisn=${nisn}&kode_bayar=${kode_bayar}`);
 
                if(!responseData.ok){
                    throw new Error(`HTTP Error! status: ${responseData.status}`);
                }
 
                const result = await responseData.json();
                console.log('Success Get Data Kekurangan PPDB: ', result);
 
                if(result.status === 'ok' && result.data && Array.isArray(result.data)){
                     
                    const formattedData = result.data.map((person) => {
                        const formattedCurrency = new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                        }).format(person.kekurangan_pembayaran);
            
                        const formattedDate = new Intl.DateTimeFormat('id-ID', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        }).format(new Date(person.tanggal_terakhir_transaksi));
                 
                        return {
                            ...person,
                            formattedCurrency,
                            formattedDate,
                        };
                    });
                    setDataKekurangan(formattedData);
                    console.log(formattedData);
                }else{
                    console.error('Invalid data structure: ', result);
                    setDataKekurangan([]);
                }

            } catch (error) {
                console.error('Error', error);
                setDataKekurangan([]);
            }
        };
        fetchDataKekurangan();
    }, [apiUrl]);

    return(
        <Card extra={"w-full h-full p-3"}>
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Table Data PPDB</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Data Riwayat Kekurangan Pembayaran PPDB
                    </p>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                Nisn
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Kekurangan Bayar
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Jenis Pembayaran
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Petugas Input
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Tanggal Transaksi Terakhir
                            </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {dataKekurangan.map((person) => (
                                <tr key={person.id} className="even:bg-gray-50">
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                        {person.nisn}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.formattedCurrency}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.jenis_pembayaran}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.petugas_input}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.formattedDate}</td>
                                </tr>
                            ))}
                        </tbody>
        </table>
    </div>
    </div>
</div>
</div>
    </Card>
    )
}


export default DetailKekuranganPpdb;