import LogoSmk from "assets/img/login/logosmk.png";
import useAuthStore from "authStore";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CetakBulanan = () => {
    const {nisn, nama, kelas, setNisn, setNama, setKelas} = useAuthStore();
    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const nisnFromQuery = queryParams.get('nisn');
        const namaFromQuery = queryParams.get('nama');
        const kelasFromQuery = queryParams.get('kelas');
        setNama(namaFromQuery);
        if (nisnFromQuery) {
            setNisn(nisnFromQuery);
            setNama(namaFromQuery);
            setKelas(kelasFromQuery);
        }
    }, [location, setNisn, setNama, setKelas]);
    console.log("nama: ", nama);
    console.log("kelas:", kelas);

    const monthName = new Date().toLocaleDateString('id-ID', {month: 'long'});

    return(
        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 ">
                {/* invoice bulanan */}
                <div id="invoice-bulanan" className="mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lgsm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
                    <div className="flex flex-col items-center justify-center mb-8">
                        <div className="flex flex-col justify-center items-center">
                            <img className="mx-auto h-16 w-auto"
                                src={LogoSmk}
                                alt="Logo SMK Nu TUlis" />
                            <div className="text-gray-700 font-semibold text-lg mr-2">SMK NU TULIS</div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between items-center mb-8">
                        <div className="flex flex-col justify-center items-center">
                            <div className="text-gray-700">
                                <div className="font-dm text-xl mb-2 mx-auto">INVOICE</div>
                            </div>
                        </div>
                    </div>
                    <h2 className="text-base font-semibold leading-6 text-gray-900">Pembayaran SPP Bulan {monthName}</h2>
                    <dl className="mt-2 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                    <div className="sm:pr-4">
                        <dd className="text-sm font-medium leading-6 text-gray-600">{nama}</dd>
                        <dd className="text-sm font-medium leading-6 text-gray-500">{nisn}</dd>
                        <dd className="text-sm font-medium leading-6 text-gray-500">{kelas}</dd>
                        {/* <dd className="text-sm font-medium leading-6 text-gray-500">{alamat}</dd> */}
                    </div>
                </dl>
                </div>
            </div>
        </div>
    )
}

export default CetakBulanan;