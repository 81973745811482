const { default: Card } = require("components/card")


const denom = [
  {kode: '001', nama: 'Maghfiroh', periode: 'Januari-juni', created: '15 November 2023'},
]

export default function TableCardPetugasPenarikan () {
    return(
        <Card extra={"w-full p-4 h-full"}>
          <div className=" w-full">
            <h4 className="text-xl font-bold text-navy-700 text-center dark:text-white">
              SMK NU TULIS
            </h4>
          <p className="mt-2 text-base text-gray-600 text-center">
            Daftar Petugas Penarikan
          </p>
        </div>
      {/* Project 1 */}
      

      <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="divide-x divide-gray-200">
                <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Kode
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Nama Petugas
                  </th>
                  <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Periode
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                    Created
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {denom.map((person) => (
                  <tr key={person.nama} className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                      {person.kode}
                    </td>
                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.nama}</td>
                    <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.periode}</td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">{person.created}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


    </Card>
    )
}
