import useAuthStore from "authStore";
import { useEffect, useState } from "react";

const DetailKekurangan = () => {

    //initiate url
    const apiUrl = process.env.REACT_APP_API_URL;
    //initiate useState
    const [dataKekurangan, setDataKekurangan] = useState([]);
    const nisn = useAuthStore((state) => state.nisn);

    useEffect(() => {
        const fetchDataKekurangan = async () => {
            console.log(nisn);

            try {
                const responseData = await fetch(`${apiUrl}/v1/laporanPembayaran/getDataListKurangBayar?nisn=${nisn}`);

                if(!responseData.ok){
                    throw new Error(`HTTP Error! status: ${responseData.status}`);
                }

                const result = await responseData.json();
                console.log('Success Get Data Kekurangan: ', result);

                if(result.status === 'ok' && result.data && Array.isArray(result.data)){
                    
                    const formattedData = result.data.map((person) => {
                        const formattedCurrency = new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                        }).format(person.kekurangan_pembayaran);
                
                        const formattedDate = new Intl.DateTimeFormat('id-ID', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        }).format(new Date(person.tanggal_terakhir_transaksi));
                
                        return {
                            ...person,
                            formattedCurrency,
                            formattedDate,
                        };
                    });
                    setDataKekurangan(formattedData);
                    console.log(formattedData);
                }else{
                    console.error('Invalid data structure: ', result);
                    setDataKekurangan([]);
                }

            } catch (error) {
                console.error('Error', error);
                setDataKekurangan([]);
            }
        };
        fetchDataKekurangan();
    }, [apiUrl]);

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Table Kekurangan Siswa</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Berikut ini adalah detail kekurangan siswa setiap kelasnya
                    </p>
                </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr className="divide-x divide-gray-200">
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Kelas
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nisn
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                      Jenis Pembayaran
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                      Kekurangan Pembayaran
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                      Tanggal Terakhir Transaksi
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                      Petugas Input
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {dataKekurangan.map((person) => (
                    //convert format currency
                    <tr key={person.id} className="divide-x divide-gray-200">
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                        {person.nama}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.kelas}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.nisn}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">{person.jenis_pembayaran}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">{person.formattedCurrency}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">{person.formattedDate}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">{person.petugas_input}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
};

export default DetailKekurangan;