const { default: Card } = require("components/card")

const CardPetugasPenarikan = () => {
    return(
        <Card extra={"w-full p-4 h-full"}>
            <div className=" w-full">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Daftar Petugas Penarikan
                </h4>
                <p className="mt-2 text-base text-gray-600">
                    Silahkan untuk Menambahkan Petugas Penarikan
                </p>
            </div>
                {/* Project 1 */}
            <div className="w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                {/* <div className="isolate bg-white px-6 py-24 sm:py-32"> */}
                <div className="items-center">
                    <form action="#" method="POST" className="mx-auto mt-0 max-w-xl sm:mt-2">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                                <label htmlFor="kode_pembayaran" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                    Nama Petugas Penarikan
                                </label>
                                <div className="mt-0">
                                    <input
                                        type="text"
                                        name="petugas_penarikan"
                                        id="petugas_penarikan"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="roleId" className="block text-sm font-semibold leading-6 text-gray-900">
                                Periode
                            </label>
                            <div className="relative mt-2.5">
                                <select
                                    id="periode"
                                    name="periode"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    <option>Januari - Juni</option>
                                    <option>Juli - Desember</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-10">
                            <button
                                type="submit"
                                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Submit Penambahan Petugas
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Card>
    )
}

export default CardPetugasPenarikan;