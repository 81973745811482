import { useEffect, useState } from "react";

const { default: Card } = require("components/card")

const TableCardJenisBayar = () => {
  
  // integration with api
  const [tableData, setTableData] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;
  
  const fetchData = () => {
    fetch(`${apiUrl}/v1/pembayaran/jenisPembayaran`)
    .then((response) =>{
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json();
    })
    .then((result) => {
      console.log('Success', result);
      if (result.status === 'ok' && result.data && Array.isArray(result.data)){
        setTableData(result.data);
      }else{
        console.error('Invalid data structure:', result);
        setTableData({});
      }
    })
    .catch((error)=>{
      console.error('Error', error);
      setTableData([]);
    })
  };

  useEffect(() => {
    fetchData();
  }, []);
      return(
          <Card extra={"w-full p-4 h-full"}>
            <div className=" w-full">
              <h4 className="text-xl font-bold text-navy-700 text-center dark:text-white">
                SMK NU TULIS
              </h4>
            <p className="mt-2 text-base text-gray-600 text-center">
              Daftar Jenis Bayar SMK NU TULIS
            </p>
          </div>
        {/* Project 1 */}
        
  
        <div className="px-4 sm:px-6 lg:px-8">
          <form onSubmit={(e) => {fetchData();}} className="mx-auto mt-16 max-w-xl sm:mt-20">
            <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr className="divide-x divide-gray-200">
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Kode
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Kelas
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Jenis Transaksi
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nominal Bulan
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nominal Total
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                      Created
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableData.map((person) => (
                    <tr key={person.id} className="divide-x divide-gray-200">
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                        {person.kode_pembayaran}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.kelas}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.jenis_transaksi}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.nominal_bulan}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.nominal_total}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">{person.created_at}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
          </form>
        
      </div>
  
  
      </Card>
      );
  
  
};

export default TableCardJenisBayar;
