import useAuthStore from "authStore";
import Card from "components/card";
import { useEffect, useState } from "react";

const General = () => {
  const [namaProfile, setNamaProfile] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL; // Update with your actual API URL
  const nama = useAuthStore((state) => state.nama);
  const nisn = useAuthStore((state) => state.nisn);

  useEffect(() => {
    const fetchProfileData = async () => {

      try {
        const response = await fetch(`${apiUrl}/v1/rombel/getListDataProfile?nama=${nama}&nisn=${nisn}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Success:', result);

        if (result.status === 'ok' && result.data && Array.isArray(result.data)) {
          setNamaProfile(result.data);
        } else {
          console.error('Invalid data structure:', result);
          setNamaProfile([]);
        }
      } catch (error) {
        console.error('Error:', error);
        setNamaProfile([]);
      }
    };

    fetchProfileData();
  }, []);
  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          General Information
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
          Ab Adinata adalah seorang siswa berprestasi yang memiliki daya tarik tinggi di bidang telekomunikasi
        </p>
      </div>
      {/* Cards */}
      {namaProfile.map((data) =>(
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Pendidikan</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            SMK NU TULIS
          </p>
        </div>
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Alamat Tempat Tinggal</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {data.alamat}
          </p>
        </div>
        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Jenis Kelamin</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {data.jk}
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Tempat Lahir</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {data.tempat_lahir}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Nomor Handphone</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {data.hp}
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Sekolah Asal</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {data.sekolah_asal}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">NISN</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {data.nisn}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Jarak Rumah Ke Sekolah</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {data.jarak_rumah_ke_sekolah} km
          </p>
        </div>
      </div>
      ))}
    </Card>
  );
};

export default General;
