import useAuthStore from "authStore";
import Card from "components/card";
import { useEffect, useState } from "react";


const RiwayatPembayaran = () => {

    //initiate api url
    const apiUrl = process.env.REACT_APP_API_URL;
    const [dataRiwayatPembayaran, setDataRiwayatPembayaran]= useState([]);
    const nisn = useAuthStore((state) => state.nisn);

    useEffect(() => {
        const fetchDataRiwayatPembayaran = async () => {

            try {
                const responseData = await fetch(`${apiUrl}/v1/riwayatPembayaran/getRiwayatPembayaran?nisn=${nisn}`);
                if(!responseData.ok){
                    throw new Error(`HTTP Error! status: ${responseData.status}`);
                }

                const result = await responseData.json();
                if(result.status === 'ok' && result.data && Array.isArray(result.data)){
                    const convertFormat = result.data.map((person) => {
                        const convertDate = new Intl.DateTimeFormat('id-ID', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                        }).format(new Date(person.tanggal_bayar));
                        //convert mata uang
                        const convertIdr = new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                        }).format(person.nominal);

                        return {
                            ...person,
                            convertDate,
                            convertIdr,
                        }
                    })
                    setDataRiwayatPembayaran(convertFormat);
                }else{
                    console.error('Invalid data structure: ', result);
                    setDataRiwayatPembayaran([]);
                }
            } catch (error) {
                console.error('Error: ', error);
                setDataRiwayatPembayaran([]);
            }
        };
        fetchDataRiwayatPembayaran();
    }, [apiUrl]);

    return(
        <Card extra={"w-full h-full p-3"}>
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Table Riwayat Pembayaran</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Data Riwayat Pembayaran Semua Transaksi
                    </p>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                Transaksi
                            </th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                Nominal
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Status
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Bulan
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Tanggal Transaksi
                            </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {dataRiwayatPembayaran.map((person) => (
                                <tr key={person.id} className="even:bg-gray-50">
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                        {person.jenis_transaksi}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.convertIdr}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.status}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.bulan}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.convertDate}</td>
                                </tr>
                            ))}
                        </tbody>
        </table>
    </div>
    </div>
</div>
</div>
    </Card>
    );
};

export default RiwayatPembayaran;