import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React from "react";
import AdminPetugasPenarikan from "./components/petugaspenarikan/AdminPetugasPenarikan";
export default function PetugasPenarikanConfig () {
    const [open, setOpen] = React.useState(true);
    return(
        <div className="flex h-full w-full">
            <Sidebar open={open} onClose={() => setOpen(false)} />
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                <main className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}>
                    <div className="h-full">
                        <Navbar />
                        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white">
                        <AdminPetugasPenarikan />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
