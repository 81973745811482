
const Widget = ({ icon, title, nominal }) => {
  return (
    // <Card extra="!flex-row flex-grow items-center rounded-[15px]">
    //   <div className="ml-[10px] flex h-[90px] w-auto flex-row items-center">
    //     <div className="rounded-full bg-lightPrimary p-2 dark:bg-navy-700">
    //       <span className="flex items-center text-lime-500 dark:text-white">
    //         {icon}
    //       </span>
    //     </div>
    //   </div>

    //   <div className="h-50 ml-3 flex w-auto flex-col justify-center">
    //     <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
    //     <h4 className="flex text-sm font-bold text-navy-700 dark:text-white">
    //       {nominal}
    //     </h4>
    //   </div>
    // </Card>
    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm border-2 border-lime-300">
    <div className="flex items-center">
        <div className="mr-4">{icon}</div>
        <div>
            <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
            <div className="text-gray-500">{nominal}</div>
        </div>
    </div>
</div>
  );
};

export default Widget;