
const Dataguru = () => {
    return(
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-white">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <p>data guru</p>
            </div>
        </div>
    )
}

export default Dataguru;