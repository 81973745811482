import AlertSuccess from "components/notification/alertNotificationSuccess";
import NotifSuccessPay from "components/notification/notifSuccesPay";
import { WalletCards } from 'lucide-react';
import { useEffect, useState } from "react";
import DataPicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LogoSmk from "../../../assets/img/login/logosmk.png";

const Pembayaran = () => {
    const [data, setData] = useState({
        nama_siswa:'',
        kelas:'X',
        nisn:   0,
        nominal_bulan:'',
        bulan_bayar:[],
        tanggal_bayar: new Date(),
        petugas_input:'',
        jenis_transaksi:'',
    });

    const englishToIndonesianMonths = {
        January: "Januari",
        February: "Februari",
        March: "Maret",
        April: "April",
        May: "Mei",
        June: "Juni",
        July: "Juli",
        August: "Agustus",
        September: "September",
        October: "Oktober",
        November: "November",
        December: "Desember"
    };
    

    const [showBuktiBayar, setShowBuktiBayar] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [availableMonths, setAvailableMonths] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isCollapsedPraktikum, setIsCollapsedPraktikum] = useState(true);
    const [isCollapsedPpdb, setIsCollapsedPpdb] = useState(true);
    const [error, setError] = useState('');
    const [showSuccessPay, setSuccessPay] = useState(false);
    
    const [kurangPraktikum, setKurangPraktikum] = useState('');
    const [kurangPpdb, setKurangPpdb] = useState('');

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    //handle for pop up
    const [openPopUp, setOpenPopUp] = useState(false);

    const handleOpenPopUp = () => {
        setOpenPopUp(true);
    }

    const handleClosePopUp = () => {
        setOpenPopUp(false);
    }

    const fetchAvailableMonth = async (nisn) => {
        try {
            const response = await fetch(`${apiUrl}/v1/laporanPembayaran/getAvailableMonth?nisn=${nisn}`);
            if (!response.ok) {
                throw new Error('Failed to fetch paid months');
            }
            const result = await response.json();
            if (result.status === 'ok' && Array.isArray(result.data)) {
                console.log("available month : ", result.data);
                setAvailableMonths(result.data);
            } else {
                console.error('Invalid response:', result);
            }
        } catch (error) {
            console.error('Error fetching available months:', error);
        }
    };


    useEffect((nisn) => {
         // Replace with actual NISN
        fetchAvailableMonth(nisn);
    }, []);

    useEffect(() => {
        console.log("availableMonths:", availableMonths);
    }, [availableMonths]);


    const handleSubmit = (e)=> {
        e.preventDefault();
        console.log('Pop up form SPP');
        handleOpenPopUp();
    };

    const handleSubmitPembayaranSPP = async (e) => {
        e.preventDefault();
        try {
            // Validasi jumlah bulan bayar
            if (data.bulan_bayar.length > parseInt(data.periode)) {
                alert('Jumlah bulan bayar tidak boleh melebihi periode.');
                return;
            }

            const response = await fetch(`${apiUrl}/v1/laporanPembayaran/addPembayaran`,{
                method: 'POST',
                headers: {
                    'Content-Type':'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            console.log('Form submitted pembayaran spp');
            // Ambil data yang diperlukan dari respons
            const responseData = await response.json();
            setSuccessPay(true);
            handleClosePopUp();
            // Navigate to another page after a delay
            setTimeout(() => {
                navigate("/admin/default");
            }, 1000); // Adjust the delay time as needed
            // window.open("/#/cetak-bulanan");
            // Buat URL baru dengan parameter query yang berisi data respons
            // const newURL = `/cetak-bulanan?data=${encodeURIComponent(JSON.stringify(responseData))}`;

            // Buka halaman baru dengan URL baru
            // window.open(newURL);
        } catch (error) {
            console.error('Error Submit Pembayaran SPP : ', error);
        }
    }

    const handleSubmitPopUp = async (e) => {
        e.preventDefault();
        try {
            // Your form submission logic for the pop-up goes here
            const today = new Date();
            const options = { month: 'long' };
            // Mengonversi nama bulan dalam bahasa Indonesia
            const currentMonth = new Intl.DateTimeFormat('id-ID', options).format(today);

            const payload = {
                ...data,
                bulan_bayar: [currentMonth] // Set bulan_bayar to currentMonth
            };
            
            const response = await fetch(`${apiUrl}/v1/laporanPembayaran/pembayaranPraktikumSave`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload), // Assuming data contains the form data
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log('Form submitted from pop-up!');
            // Close the pop-up after form submission
            setSuccessPay(true);
            handleClosePopUp();

                // Navigate to another page after a delay
            setTimeout(() => {
                navigate("/admin/default");
            }, 1000); // Adjust the delay time as needed
            
        } catch (error) {
            console.error('Error submitting form from pop-up:', error);
            // Handle error or show error message to the user
        }
    };

    const handleSubmitPpdbForPopUp = async (e) => {
        e.preventDefault();
        try {
            // Your form submission logic for the pop-up goes here
            const today = new Date();
            const options = { month: 'long'};
            const currentMonth = new Intl.DateTimeFormat('id-ID', options).format(today); 

            const payload = {
                ...data,
                bulan_bayar: [currentMonth] // Set bulan_bayar to currentMonth
            };
    
            const response = await fetch(`${apiUrl}/v1/laporanPembayaran/pembayaranPpdbSave`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload), // Assuming data contains the form data
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log('Form submitted from pop-up!');
            // Close the pop-up after form submission
            setSuccessPay(true);

            handleClosePopUp();

                // Navigate to another page after a delay
            setTimeout(() => {
                navigate("/admin/default");
            }, 1000); // Adjust the delay time as needed
            
        } catch (error) {
            console.error('Error submitting form from pop-up:', error);
            // Handle error or show error message to the user
        }
    };
    
    

    const handleSubmitPraktikum = (e)=> {
        e.preventDefault();

        console.log('Form submitted!');

        handleOpenPopUp();
        

        
    };

    const handleSubmitPpdb = (e)=> {
        e.preventDefault();

        console.log('Form submitted!');

        handleOpenPopUp();
        

        
    };


    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        let updatedData = { ...data };
    
        if (name === 'periode') {
            const nominal_bulan = parseInt(value) * 100000;
            const selectedPeriode = parseInt(value);
            const selectedMonths = availableMonths.slice(0, selectedPeriode); // Ambil bulan sesuai dengan periode yang dipilih
            updatedData = {
                ...updatedData,
                [name]: value,
                nominal_bulan: nominal_bulan.toString(),
                bulan_bayar: selectedMonths
            };

        } else if (name === 'bulan_bayar') {
            if (checked) {
                // Check if the number of selected months is less than or equal to the selected periode
                if (updatedData['bulan_bayar'].length < parseInt(updatedData.periode)) {
                    setError('Bulan bayar tidak sesuai dengan periode jumlah');
                    updatedData[name] = [...updatedData[name], value];
                    console.log('checked: ', updatedData);
                }
            } else {
                updatedData[name] = updatedData[name].filter(month => month !== value);
                console.log('updatedate else : ', updatedData);
            }
        } else {
            updatedData = {
                ...updatedData,
                [name]: value,
            };
        }
    
        setData(updatedData);
    };
    

    const handleChangeDate = (date) => {
        // 'date' parameter will be the selected date
        setStartDate(date);

        // Convert the date to a format that includes milliseconds
        const formattedDate = date.toISOString();

    
        setData({ ...data, tanggal_bayar: formattedDate }); // Assuming you want to store it in 'tanggal_lahir'
    };

    const handleAlertClose = () => {
        setSuccessPay(false);
    };



    // hit api to get all kelas
    const [allKelas, setAllKelas] = useState([]);
    
    useEffect(() => {
        const fetchAllKelas = async () => {
            try {
                const responseAllKelas = await fetch(`${apiUrl}/v1/rombel/getAllKelas`);

                if (!responseAllKelas.ok){
                    throw new Error(`HTTP Error! status: ${responseAllKelas.status}`);
                }

                const result = await responseAllKelas.json();
                console.log('Success : ', result);

                if (result.status === 'ok' && result.data && Array.isArray(result.data)) {
                    setAllKelas(result.data);
                }
            } catch (error) {
                console.error('Error at get all kelas', error);
                setAllKelas([]);
            }
        };

        fetchAllKelas();
    }, []);

    const fetchDataKekurangan = async (nisn) => {
        try {
            const response = await fetch(`${apiUrl}/v1/laporanPembayaran/getKeuanganSiswa?nisn=${nisn}&kode_bayar=001`);
            if (!response.ok) {
                throw new Error(`Http Error !, Status : ${response.status}`);
            }
    
            const result = await response.json();
            console.log("result: ", result);
            if (result.status === 'ok' && result.data && Array.isArray(result.data.item)) {

                const formattedItems = result.data.item.map((item) => {
                    const formattedEntries = item.entries.map((entry) => ({
                        ...entry,
                        nominal_bulan: new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                        }).format(entry.nominal_bulan)
                    }));
    
                    return {
                        ...item,
                        total: new Intl.NumberFormat('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                        }).format(item.total),
                        entries: formattedEntries
                    };
                });

                const formattedKurangPraktikum = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                }).format(result.data.item[1].total);
    
                const formattedKurangPpdb = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                }).format(result.data.item[2].total);
                console.log('format kurang ppdb : ', formattedKurangPpdb);
                setKurangPraktikum(formattedKurangPraktikum);
                setKurangPpdb(formattedKurangPpdb);
    
            } else {
                console.error('Invalid data structure:', result);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    


    // hit api to get data kelas with nama and nisn

    useEffect(() => {
        fetchAllDataKelas();
    }, [data]);

    const [dataAllKelas, setDataAllKelas] = useState([])

    const fetchAllDataKelas = () => {
        fetch(`${apiUrl}/v1/rombel/getListRombel?rombel_saat_ini=${data.kelas}`)
        .then((response) => {
            if(!response.ok){
                throw new Error('Network response was not oke');
            }
            return response.json();
        })
        .then((result) => {
            console.log('Success:', result);
            if(result.status === 'ok' && result.data && Array.isArray(result.data)){
                setDataAllKelas(result.data);
            }else{
                console.error('Invalid data structure:', result);
                setDataAllKelas([]);
            }
        })
        .catch((error)=> {
            console.error('Error:', error);
            setDataAllKelas([]);
        })
    }

    const handleNameSelect = (selectedName) => {
        // Find the corresponding student by name and set the nisn in the state
        const selectedStudent = dataAllKelas.find((student) => student.nama === selectedName);
        if (selectedStudent) {
            const selectedNisn = selectedStudent.nisn;
            setData({ ...data, nisn: selectedNisn, nama_siswa:selectedName });
        
            fetchAvailableMonth(selectedNisn);
            fetchDataKekurangan(selectedNisn);
        }
    };
    

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        if (isCollapsed){
            setData({
                nama_siswa: '',
                kelas: '',
                nisn: '',
                nominal_bulan:'',
                bulan_bayar:'',
                petugas_input:'',
                jenis_transaksi:'',
            })
        }
    }

    const toggleCollapsePraktikum = () => {
        setIsCollapsedPraktikum(!isCollapsedPraktikum);
        if (isCollapsedPraktikum) {
            setData({
                nama_siswa: '',
                kelas: '',
                nisn: '',
                // tambahkan properti lain jika diperlukan
            });
            setKurangPraktikum('');
        }
    }

    const toggleCollapsePpdb = () => {
        setIsCollapsedPpdb(!isCollapsedPpdb);
        if (isCollapsedPpdb) {
            setData({
                nama_siswa: '',
                kelas: '',
                nisn: '',
                // tambahkan properti lain jika diperlukan
            });
            setKurangPpdb('');
        }
    }

    const today = new Date();
    const currentMonth = today.toLocaleString('default', { month: 'long' }); // Get the current month in full name (e.g., "January")
    const currentMonthIndonesian = englishToIndonesianMonths[currentMonth];
    const currentMonthArray = [currentMonthIndonesian];
    


    
    return(
        <div>
            <div className="isolate bg-white px-1 py-4 sm:py-4 lg:px-1">
                <div className="mx-auto max-w-2xl text-center">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            className="mx-auto h-24 w-auto"
                            src={LogoSmk}
                            alt="Logo SMK NU Tulis"
                        />
                        <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Input Pembayaran
                        </h2>
                        <h1>
                            SMK NU TULIS BATANG
                        </h1>
                    </div>
                </div>
            </div>
            <div className="table-header border-collapse">
                <div onClick={toggleCollapse} className={`collapse-title bg-lime-400 mt-1 text-xl font-medium font-sans cursor-pointer flex justify-between items-center rounded-md ${isCollapsed ? 'font-bold' : 'normal'}`}>
                    <span className="text-center text-lg font-bold leading-9 tracking-tight ml-2 text-gray-700">Pembayaran SPP</span>
                    <span>{isCollapsed ? <FaChevronRight  className="text-white"/> : <FaChevronDown className="text-white"/>}</span>
                </div>
                {isCollapsed ? null : (
                    <div className="collapse-content">
                        <div className="isolate bg-white px-1 py-4 sm:py-4 lg:px-1">
                            <form onSubmit={handleSubmit} className="mx-auto mt-2 max-w-xl sm:mt-2">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-2">
                                    {/* jenis transaksi */}
                                    <label htmlFor="jenis_transaksi" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Jenis Transaksi
                                    </label>

                                    <div className="flex items-center gap-x-3">
                                        <input
                                            type="radio"
                                            id="jenis_transaksi"
                                            name="jenis_transaksi"
                                            value="SPP"
                                            onChange={handleChange}
                                            className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-600" />
                                            <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                                                SPP
                                            </label>
                                    </div>

                                    <label htmlFor="kelas" className="block max-w-none items-center text-sm text-left font-semibold leading-6 text-gray-900">
                                        Kelas
                                    </label>
                                    <div className="mt-2 items-center">
                                        <select
                                            id="kelas"
                                            name="kelas"
                                            value={data.kelas}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value="">Select kelas</option>
                                            {allKelas.map((kelas, index) => (
                                                <option key={index} value={kelas.rombel_saat_ini}>
                                                    {kelas.rombel_saat_ini}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <label htmlFor="studentName" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Select Student
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="studentName"
                                            name="studentName"
                                            value={data.nama_siswa}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleNameSelect(e.target.value);
                                            }}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                            >
                                            <option value="">Select Student</option>
                                                {dataAllKelas.map((student, index) => (
                                            <option key={index} value={student.nama}>
                                                {student.nama}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <label htmlFor="nisn" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        NISN
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="number"
                                            name="nisn"
                                            id="nisn"
                                            value={data.nisn}
                                            onChange={handleChange}
                                            placeholder="masukkan NISN"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>

                                    <label htmlFor="periode" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                            Jumlah Bulan yang akan di bayar
                                    </label>
                                    <div className="flex items-center gap-x-3">
                                        <select
                                            id="periode"
                                            name="periode"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            value={data.periode}
                                            onChange={handleChange}
                                        >
                                            <option value="0">0</option>
                                            {[...Array(12).keys()].map((month) => (
                                                <option key={month} value={month + 1}>{month + 1}</option>
                                            ))}
                                        </select>
                                    </div>


                                    <label htmlFor="nominal_bulan" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Jumlah Bayar
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="number"
                                            name="nominal_bulan"
                                            id="nominal_bulan"
                                            value={data.nominal_bulan}
                                            onChange={handleChange}
                                            placeholder="masukkan nominal"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                
                                    <label htmlFor="bulan_bayar" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Bulan
                                    </label>

                                    <div className="mt-2.5">
                                        {Array.isArray(availableMonths) && availableMonths.map((month, index) => (
                                            <div key={index} className="flex items-center gap-x-3">
                                                <input
                                                    id={`bulan_${month}`}
                                                    name="bulan_bayar"
                                                    type="checkbox" // Change input type to "checkbox"
                                                    value={month}
                                                    onChange={handleChange}
                                                    className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-600"
                                                />
                                                <label htmlFor={`bulan_${month.tahun_pelajaran}`} className="block text-sm font-medium leading-6 text-gray-900">{month.tahun_pelajaran}</label>
                                            </div>
                                        ))}
                                    </div>


                                    <label htmlFor="tanggal_bayar" className="block max-w-none text-left mr-16 font-medium sm:text-sm leading-6 text-gray-900">
                                        Tanggal Bayar
                                    </label>
                                    <div className="mt-2.5">
                                        <DataPicker
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            selected={startDate}
                                            onChange={handleChangeDate}
                                            showTimeSelect
                                            timeFormat="HH:mm:ss"
                                            timeIntervals={1}
                                            dateFormat="MMMM d, yyyy h:mm:ss aa"
                                        />
                                    </div>
                                    <label htmlFor="petugas_input" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Petugas Input
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            name="petugas_input"
                                            id="petugas_input"
                                            value={data.petugas_input}
                                            onChange={handleChange}
                                            placeholder="masukkan nama petugas"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                        />
                                    </div>

                                    <div className="text-right">
                                        <button
                                            type="submit"
                                            onClick={handleSubmit}
                                            className="flex justify-center rounded-md bg-lime-600 px-8 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">
                                            Submit Pembayaran
                                        </button>
                                    </div>
                                    {/* render pop up if openPopUp is true */}
                                    {openPopUp && (
                                        <NotifSuccessPay open={openPopUp} onClose={handleClosePopUp}>
                                            <form onSubmit={handleSubmitPembayaranSPP} className="mx-auto mt-2 max-w-xl sm:mt-2">
                                                <div className="text-center w-56">
                                                    {/* Pop-up content */}
                                                    <WalletCards size={56} className="mx-auto text-lime-700" />
                                                    <div className="mx-auto my-4 w-48">
                                                        <h3 className="text-lg font-black text-gray-800">
                                                            Confirm Pembayaran
                                                        </h3>
                                                        <p className="text-sm text-gray-500">
                                                            Apakah data yang dimasukkan sudah sesuai 
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-4">
                                                        {/* Buttons inside the pop-up */}
                                                        <button type="button" className="btn btn-danger w-full text-lime-600"
                                                            onClick={handleSubmitPembayaranSPP}>
                                                            Bayar
                                                        </button>
                                                        <button type="button" className="btn btn-light w-full" onClick={handleClosePopUp}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </NotifSuccessPay>
                                    )}
                                    {showSuccessPay && <AlertSuccess open={showSuccessPay} onClose={handleAlertClose}>Payment Successful!</AlertSuccess>}
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <div className="table-header border-collapse">
                <div onClick={toggleCollapsePraktikum} className={`collapse-title bg-lime-400 mt-1 text-xl font-medium font-sans cursor-pointer flex justify-between items-center rounded-md ${isCollapsedPraktikum ? 'font-bold' : 'normal'}`}>
                    <span className="text-center text-lg font-bold leading-9 tracking-tight ml-2 text-gray-700">Pembayaran Praktikum</span>
                    <span>{isCollapsedPraktikum ? <FaChevronRight className="text-white"/> : <FaChevronDown className="text-white"/>}</span>
                </div>
                {isCollapsedPraktikum ? null : (
                    <div className="collapse-content">
                        <div className="isolate bg-white px-1 py-4 sm:py-4 lg:px-1">
                            <form onSubmit={handleSubmitPraktikum} className="mx-auto mt-2 max-w-xl sm:mt-2">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">

                                    {/* jenis transaksi */}
                                    <label htmlFor="jenis_transaksi" className="block text-sm font-semibold leading-6">
                                        Jenis Transaksi
                                    </label>

                                    <div className="flex items-center gap-x-3">
                                        <input 
                                            type="radio"
                                            id="jenis_transaksi"
                                            name="jenis_transaksi"
                                            value="Praktik"
                                            onChange={handleChange}
                                            className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-600" />
                                            <label htmlFor="push_everything" className="block text-sm font-medium leading-6 text-gray-900">
                                                PRAKTIKUM
                                            </label>
                                    </div>

                                    {/* kelas */}
                                    <label htmlFor="kelas" className="block max-w-none text-left mr-16 font-semibold sm:text-sm leading-6 text-gray-900">
                                        Kelas
                                    </label>
                                    <div className="mt-2 items-center">
                                        <select
                                            id="kelas"
                                            name="kelas"
                                            value={data.kelas}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value="">Select kelas</option>
                                            {allKelas.map((kelas, index) => (
                                                <option key={index} value={kelas.rombel_saat_ini}>
                                                    {kelas.rombel_saat_ini}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* nama siswa */}
                                    <label htmlFor="studentName" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Select Student
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="studentName"
                                            name="studentName"
                                            value={data.nama_siswa}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleNameSelect(e.target.value);
                                            }}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                            >
                                            <option value="">Select Student</option>
                                                {dataAllKelas.map((student, index) => (
                                            <option key={index} value={student.nama}>
                                                {student.nama}
                                            </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* NISN */}
                                    <label htmlFor="nisn" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        NISN
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="number"
                                            name="nisn"
                                            id="nisn"
                                            value={data.nisn}
                                            onChange={handleChange}
                                            placeholder="masukkan NISN"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>

                                    {/* kurang bayar */}
                                    <label htmlFor="kurang_bayar_praktikum" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Kurang Bayar Praktikum
                                    </label>
                                    <div className="mt-2.5">
                                        <input 
                                            type="text"
                                            nama="kurang_bayar_praktikum"
                                            id="kurang_bayar_praktikum"
                                            value={kurangPraktikum}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6" />
                                    </div>

                                    {/* jumlah yang akan dibayarkan */}
                                    <label htmlFor="nominal_bulan" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Jumlah Bayar
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="number"
                                            name="nominal_bulan"
                                            id="nominal_bulan"
                                            value={data.nominal_bulan}
                                            onChange={handleChange}
                                            placeholder="masukkan nominal"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>

                                    {/* bulan bayar */}
                                    <label htmlFor="bulan_bayar" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Bulan
                                    </label>
                                    <div className="mt-2.5">
                                        <input 
                                            type="text"
                                            name="bulan_bayar"
                                            id="bulan_bayar"
                                            value={currentMonthArray.join(', ')}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                            />
                                    </div>

                                    {/* tanggal bayar */}
                                    <label htmlFor="tanggal_bayar" className="block max-w-none text-left mr-16 font-medium sm:text-sm leading-6 text-gray-900">
                                        Tanggal Bayar
                                    </label>
                                    <div className="mt-2.5">
                                        <DataPicker
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            selected={startDate}
                                            onChange={handleChangeDate}
                                        />
                                    </div>
                                    
                                    {/* petugas input */}
                                    <label htmlFor="petugas_input" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Petugas Input
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            name="petugas_input"
                                            id="petugas_input"
                                            value={data.petugas_input}
                                            onChange={handleChange}
                                            placeholder="masukkan nama petugas"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                        />
                                    </div>

                                    <div className="text-right">
                                        <button
                                            type="button"
                                            onClick={handleSubmitPraktikum}
                                            className="flex justify-center rounded-md bg-lime-600 px-8 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">
                                            Submit Pembayaran
                                        </button>
                                    </div>
                                    {/* render pop up if openPopUp is true */}
                                    {openPopUp && (
                                        <NotifSuccessPay open={openPopUp} onClose={handleClosePopUp}>
                                            <form onSubmit={handleSubmitPopUp} className="mx-auto mt-2 max-w-xl sm:mt-2">
                                                <div className="text-center w-56">
                                                    {/* Pop-up content */}
                                                    <WalletCards size={56} className="mx-auto text-lime-700" />
                                                    <div className="mx-auto my-4 w-48">
                                                        <h3 className="text-lg font-black text-gray-800">
                                                            Confirm Pembayaran
                                                        </h3>
                                                        <p className="text-sm text-gray-500">
                                                            Apakah data yang dimasukkan sudah sesuai 
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-4">
                                                        {/* Buttons inside the pop-up */}
                                                        <button type="button" className="btn btn-danger w-full text-lime-600"
                                                            onClick={handleSubmitPopUp}>
                                                            Bayar
                                                        </button>
                                                        <button type="button" className="btn btn-light w-full" onClick={handleClosePopUp}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </NotifSuccessPay>
                                    )}
                                    {showSuccessPay && <AlertSuccess open={showSuccessPay} onClose={handleAlertClose}>Payment Successful!</AlertSuccess>}
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <div className="table-header border-collapse">
                <div onClick={toggleCollapsePpdb} className={`collapse-title bg-lime-400 mt-1 text-xl font-medium font-sans cursor-pointer flex justify-between items-center rounded-md ${isCollapsedPraktikum ? 'font-bold' : 'normal'}`}>
                    <span className="text-center text-lg font-bold leading-9 tracking-tight ml-2 text-gray-700">Pembayaran PPDB</span>
                    <span>{isCollapsedPpdb ? <FaChevronRight className="text-white"/> : <FaChevronDown className="text-white"/>}</span>
                </div>
                {isCollapsedPpdb ? null : (
                    <div className="collapse-content">
                        <div className="isolate bg-white px-1 py-4 sm:py-4 lg:px-1">
                            <form onSubmit={handleSubmitPpdb} className="mx-auto mt-2 max-w-xl sm:mt-2">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">

                                    {/* jenis transaksi */}
                                    <label htmlFor="jenis_transaksi" className="block text-sm font-semibold leading-6">
                                        Jenis Transaksi
                                    </label>

                                    <div className="flex items-center gap-x-3">
                                        <input 
                                            type="radio"
                                            id="jenis_transaksi"
                                            name="jenis_transaksi"
                                            value="PPDB"
                                            onChange={handleChange}
                                            className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-600" />
                                            <label htmlFor="push_everything" className="block text-sm font-medium leading-6 text-gray-900">
                                                PPDB
                                            </label>
                                    </div>

                                    {/* kelas */}
                                    <label htmlFor="kelas" className="block max-w-none text-left mr-16 font-semibold sm:text-sm leading-6 text-gray-900">
                                        Kelas
                                    </label>
                                    <div className="mt-2 items-center">
                                        <select
                                            id="kelas"
                                            name="kelas"
                                            value={data.kelas}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        >
                                            <option value="">Select kelas</option>
                                            {allKelas.map((kelas, index) => (
                                                <option key={index} value={kelas.rombel_saat_ini}>
                                                    {kelas.rombel_saat_ini}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* nama siswa */}
                                    <label htmlFor="studentName" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Select Student
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id="studentName"
                                            name="studentName"
                                            value={data.nama_siswa}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleNameSelect(e.target.value);
                                            }}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                            >
                                            <option value="">Select Student</option>
                                                {dataAllKelas.map((student, index) => (
                                            <option key={index} value={student.nama}>
                                                {student.nama}
                                            </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* NISN */}
                                    <label htmlFor="nisn" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        NISN
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="number"
                                            name="nisn"
                                            id="nisn"
                                            value={data.nisn}
                                            onChange={handleChange}
                                            placeholder="masukkan NISN"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>

                                    {/* kurang bayar */}
                                    <label htmlFor="kurang_bayar_praktikum" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Kurang Bayar Praktikum
                                    </label>
                                    <div className="mt-2.5">
                                        <input 
                                            type="text"
                                            nama="kurang_bayar_praktikum"
                                            id="kurang_bayar_praktikum"
                                            value={kurangPpdb}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6" />
                                    </div>

                                    {/* jumlah yang akan dibayarkan */}
                                    <label htmlFor="nominal_bulan" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Jumlah Bayar
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="number"
                                            name="nominal_bulan"
                                            id="nominal_bulan"
                                            value={data.nominal_bulan}
                                            onChange={handleChange}
                                            placeholder="masukkan nominal"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>

                                    {/* bulan bayar */}
                                    <label htmlFor="bulan_bayar" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Bulan
                                    </label>
                                    <div className="mt-2.5">
                                        <input 
                                            type="text"
                                            name="bulan_bayar"
                                            id="bulan_bayar"
                                            value={currentMonthArray.join(', ')}
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                            />
                                    </div>

                                    {/* tanggal bayar */}
                                    <label htmlFor="tanggal_bayar" className="block max-w-none text-left mr-16 font-medium sm:text-sm leading-6 text-gray-900">
                                        Tanggal Bayar
                                    </label>
                                    <div className="mt-2.5">
                                        <DataPicker
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                            selected={startDate}
                                            onChange={handleChangeDate}
                                            placeholderText="masukan tanggal"
                                        />
                                    </div>
                                    
                                    {/* petugas input */}
                                    <label htmlFor="petugas_input" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                        Petugas Input
                                    </label>
                                    <div className="mt-2.5">
                                        <input
                                            type="text"
                                            name="petugas_input"
                                            id="petugas_input"
                                            value={data.petugas_input}
                                            onChange={handleChange}
                                            placeholder="masukkan nama petugas"
                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                        />
                                    </div>

                                    <div className="text-right">
                                        <button
                                            type="button"
                                            onClick={handleSubmitPpdb}
                                            className="flex justify-center rounded-md bg-lime-600 px-8 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600">
                                            Submit Pembayaran
                                        </button>
                                    </div>
                                    {/* render pop up if openPopUp is true */}
                                    {openPopUp && (
                                        <NotifSuccessPay open={openPopUp} onClose={handleClosePopUp}>
                                            <form onSubmit={handleSubmitPpdbForPopUp} className="mx-auto mt-2 max-w-xl sm:mt-2">
                                                <div className="text-center w-56">
                                                    {/* Pop-up content */}
                                                    <WalletCards size={56} className="mx-auto text-lime-700" />
                                                    <div className="mx-auto my-4 w-48">
                                                        <h3 className="text-lg font-black text-gray-800">
                                                            Confirm Pembayaran
                                                        </h3>
                                                        <p className="text-sm text-gray-500">
                                                            Apakah data yang dimasukkan sudah sesuai 
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-4">
                                                        {/* Buttons inside the pop-up */}
                                                        <button type="button" className="btn btn-danger w-full text-lime-600"
                                                            onClick={handleSubmitPpdbForPopUp}>
                                                            Bayar
                                                        </button>
                                                        <button type="button" className="btn btn-light w-full" onClick={handleClosePopUp}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </NotifSuccessPay>
                                    )}
                                    {showSuccessPay && <AlertSuccess open={showSuccessPay} onClose={handleAlertClose}>Payment Successful!</AlertSuccess>}
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Pembayaran;