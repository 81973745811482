export const columnsDataDevelopment = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "TECH",
      accessor: "tech",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
  ];
  
  export const columnsDataCheck = [
    {
      Header: "NAMA SISWA",
      accessor: "siswa",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];
  
  export const columnsDataColumns = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];
  
  export const columnsDataComplex = [
    {
      Header: "Transaksi",
      accessor: "name",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Tanggal",
      accessor: "date",
    }
  ];
  