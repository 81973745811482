
const Header = (props) => {
    return(
        <header id="header" className="relative w-full h-screen bg-cover bg-center" style={{ backgroundImage: 'url(YourBackgroundImageURL)' }}>
            <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="container mx-auto flex justify-center items-center h-full">
                 <div className="text-center text-white">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-4">
                        {props.data ? props.data.title : "Loading"}
                    </h1>
          <p className="text-lg md:text-xl lg:text-2xl mb-8">
            {props.data ? props.data.paragraph : "Loading"}
          </p>
          <a
            href="#/features"
            className="inline-block px-8 py-3 text-lg font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition duration-300"
          >
            Learn More
          </a>
        </div>
      </div>
    </header>
    )
}

export default Header;