import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import DataPicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import NotifSuccess from "../../../components/notification/successSave";

const Registration = () => {
  const [data, setData] = useState({
    nama_siswa:'',
    tempat_lahir:'',
    tanggal_lahir: new Date(),
    alamat:'',
    no_hp:'',
    email:'',
    nama_orang_tua:'',
    pekerjaan_orangtua:'',
    kelas_saat_ini: 0,
    role_id_siswa: 1,
    created_at: new Date(),
    asal_sekolah:'',
    golongan_darah:'',
    no_hp_orang_tua:'',
    riwayat_penyakit:'',
    nisn:0,
  });

  const [showNotification, setShowNotification] = useState(false);

  const [startDate, setStartDate] = useState(new Date());

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`http://localhost:8081/v1/siswa/addSiswa`, {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if(!response.ok){
        throw new Error('Network response was not oke');
      }else{
        return response.json();
      }
    })
    .then((data) => {
      console.log('Success:', data);
      //clear data after succes hit
      setData({
        nama_siswa:'',
        tempat_lahir:'',
        tanggal_lahir:'',
        alamat:'',
        no_hp:'',
        email:'',
        nama_orang_tua:'',
        pekerjaan_orangtua:'',
        kelas_saat_ini:'',
        role_id_siswa:'',
        asal_sekolah:'',
        golongan_darah:'',
        no_hp_orang_tua:'',
        riwayat_penyakit:'',
        nisn:'',
      });

      // Show success notification
        setShowNotification(true);

        // Hide notification after a few seconds (adjust as needed)
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);

    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeDate = (date) => {
    // 'date' parameter will be the selected date
    setStartDate(date);

    setData({ ...data, tanggal_lahir: date }); // Assuming you want to store it in 'tanggal_lahir'
  };

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Form Registration</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Masukkan data guru atau siswa kedalam form berikut ini
        </p>
      </div>
      <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label htmlFor="nama_siswa" className="block text-sm font-semibold leading-6 text-gray-900">
              Nama Siswa
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="nama_siswa"
                id="nama_siswa"
                value={data.nama_siswa}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="tempat_lahir" className="block text-sm font-semibold leading-6 text-gray-900">
              Tempat Lahir
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="tempat_lahir"
                id="tempat_lahir"
                value={data.tempat_lahir}
                onChange={handleChange}
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2 flex items-center">
            <label htmlFor="tanggal_lahir" className="block max-w-none text-left mr-16 font-bold sm:text-sm leading-6 text-gray-900">
                Tanggal Lahir
            </label>
            <div className="mt-2.5">
                <DataPicker
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    selected={startDate}
                    onChange={handleChangeDate}
                />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="alamat" className="block text-sm font-semibold leading-6 text-gray-900">
              Alamat
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="alamat"
                id="alamat"
                value={data.alamat}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="no_hp" className="block text-sm font-semibold leading-6 text-gray-900">
              Nomor Handphone
            </label>
            <div className="relative mt-2.5">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="negara" className="sr-only">
                  Negara
                </label>
                <select
                  id="negara"
                  name="negara"
                  className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                >
                  <option>ID</option>
                </select>
                <ChevronDownIcon
                  className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="no_hp"
                id="no_hp"
                value={data.no_hp}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                id="email"
                value={data.email}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="nama_orang_tua" className="block text-sm font-semibold leading-6 text-gray-900">
              Nama Orang Tua
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="nama_orang_tua"
                id="nama_orang_tua"
                value={data.nama_orang_tua}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="pekerjaan_orangtua" className="block text-sm font-semibold leading-6 text-gray-900">
              Pekerjaan Orang Tua
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="pekerjaan_orangtua"
                id="pekerjaan_orangtua"
                value={data.pekerjaan_orangtua}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="kelas_saat_ini" className="block text-sm font-semibold leading-6 text-gray-900">
              Kelas Saat Ini
            </label>
            <div className="mt-2.5">
              <input
                type="number"
                name="kelas_saat_ini"
                id="kelas_saat_ini"
                value={data.kelas_saat_ini}
                onChange={handleChange}
                placeholder='0'
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
              <label htmlFor="role_id_siswa" className="block text-sm font-semibold leading-6 text-gray-900">
                Role
              </label>
              <div className="relative mt-2.5">
                <select
                  id="role_id_siswa"
                  name="role_id_siswa"
                  value={data.role_id_siswa}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value={1}>Siswa</option>
                  <option value={2}>Guru</option>
                  <option value={3}>Bendahara Sekolah</option>
                  <option value={4}>Bendahara Bos</option>
                  <option value={5}>Kepala Sekolah</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-2">
            <label htmlFor="asal_sekolah" className="block text-sm font-semibold leading-6 text-gray-900">
              Asal Sekolah
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="asal_sekolah"
                id="asal_sekolah"
                value={data.asal_sekolah}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="golongan_darah" className="block text-sm font-semibold leading-6 text-gray-900">
              Golongan Darah
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="golongan_darah"
                id="golongan_darah"
                value={data.golongan_darah}
                onChange={handleChange}
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="no_hp_orang_tua" className="block text-sm font-semibold leading-6 text-gray-900">
              No Hp Orang Tua
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="no_hp_orang_tua"
                id="no_hp_orang_tua"
                value={data.no_hp_orang_tua}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="riwayat_penyakit" className="block text-sm font-semibold leading-6 text-gray-900">
              Riwayat Penyakit
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="riwayat_penyakit"
                id="riwayat_penyakit"
                value={data.riwayat_penyakit}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="nisn" className="block text-sm font-semibold leading-6 text-gray-900">
              NISN
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="nisn"
                id="nisn"
                value={data.nisn}
                onChange={handleChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Register Siswa
          </button>
        </div>
      </form>
      {showNotification && (
                      <NotifSuccess />
                    )}
    </div>
  )
}

export default Registration;
