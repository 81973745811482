/* eslint-disable */
import useAuthStore from "authStore";
import DashIcon from "components/icons/DashIcon";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };


  const roles = useAuthStore((state) => state.role);
  const nama_role = useAuthStore((state) => state.roleName);
  console.log('roles zustand: ', roles);
  console.log('role management from zustand: ', nama_role);
  const createLinks = (routes) => {

    const [openSubMenu, setOpenSubMenu] = useState(null);

    const handleSubMenuClick = (index) => {
      setOpenSubMenu(openSubMenu === index ? null : index);
    };

    
    return routes.map((route, index) => {
      if ((
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl") && (route.role === roles || route.rolesadmin === nama_role)
        
      ){
          return (
            <Link key={index} to={route.layout + "/" + route.path }>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${activeRoute(route.path) === true ? "font-bold text-lime-600 dark:text-white" : "font-medium text-gray-600" }`} >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${ activeRoute(route.path) === true ? "font-bold text-navy-700 dark:text-white" : "font-medium text-gray-600" }`} >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (<div class="absolute right-0 top-px h-9 w-1 rounded-lg bg-lime-600 dark:bg-lime-600" /> ) : null}
              </div>
            </Link>
          );
        } 
        else{
          if(route.submenu){
            <Link key={index} to={route.layout + "/" + route.path}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div class="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>
          }
        }
      }
    )}
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
