import { useEffect, useState } from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import SmoothScroll from 'smooth-scroll';
import Header from './component/header';
import Navigation from './component/navigation';
import JsonData from './data/data.json';


export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });
  

const LandingPage = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
    return(
        <div>
            <Navigation />
            <Header data={landingPageData.Header} />
        </div>
        
        
    )
}

export default LandingPage