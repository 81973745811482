import { useState } from "react";

const Navigation = (props) => {
    const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

    return(
        <nav id="menu" className="fixed top-0 w-full bg-white shadow-md z-50">
        <div className="container mx-auto flex flex-wrap items-center justify-between p-4">
            <div className="flex items-center justify-between w-full lg:w-auto">
                <a className="text-xl font-bold" href="#page-top">
                    React Landing Page
                </a>
            <button
                type="button"
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600 lg:hidden"
                onClick={toggleMenu}
            >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
            </svg>
            </button>
          </div>
          <div className={`lg:flex lg:items-center ${isOpen ? 'block' : 'hidden'}`}>
            <ul className="flex flex-col lg:flex-row lg:space-x-6">
              <li>
                <a href="#/features" className="block px-3 py-2 text-gray-700 hover:text-gray-900">
                  Features
                </a>
              </li>
              <li>
                <a href="#about" className="block px-3 py-2 text-gray-700 hover:text-gray-900">
                  About
                </a>
              </li>
              <li>
                <a href="#services" className="block px-3 py-2 text-gray-700 hover:text-gray-900">
                  Services
                </a>
              </li>
              <li>
                <a href="#portfolio" className="block px-3 py-2 text-gray-700 hover:text-gray-900">
                  Gallery
                </a>
              </li>
              <li>
                <a href="#testimonials" className="block px-3 py-2 text-gray-700 hover:text-gray-900">
                  Testimonials
                </a>
              </li>
              <li>
                <a href="#team" className="block px-3 py-2 text-gray-700 hover:text-gray-900">
                  Team
                </a>
              </li>
              <li>
                <a href="#contact" className="block px-3 py-2 text-gray-700 hover:text-gray-900">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
}

export default Navigation