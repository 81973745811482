import QRReader from "modern-react-qr-reader";
import { useState } from "react";
import DataPicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.module.css';
import LogoSmk from "../../../assets/img/login/logosmk.png";

const PembayaranQR = () => {

    const [formData, setFormData] = useState({
        nama: '',
        kelas: '',
    });

    const [scanning, setScanning] = useState(false);
    
    const handleScan = (data) => {
        if (data && scanning) {
            try {
                const parsedData = JSON.parse(data);
                const {nama, kelas} = parsedData;
                setFormData({ nama, kelas });
                setScanning(false);
            } catch (error) {
                console.error('Invalid QR Code format');
            }
        }
    };


    const handleError = (error) => {
        console.error(error);
    };

    const previewStyle = {
        width: '100%',
        height: '100%',
        borderRadius: '10%',
    };

    const [startDate, setStartDate] = useState(new Date());


    return(
        <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center"> 
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-24 w-auto"
                        src={LogoSmk}
                        alt="Logo SMK NU Tulis"
                        />
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <div style={{borderRadius: '10%', overflow:'hidden'}}>
                            {scanning ? (
                                < QRReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                style={previewStyle}
                                facingMode={scanning ? 'environtment' : 'user'}
                                onResult={(result, error) => {
                                if (!!result) {
                                    handleScan(result?.text);
                                }
    
                                if (!!error) {
                                    console.info(error);
                                }
                                }}
                            />
                            ): null}
                        </div>
                        {scanning ? (
                            <button
                                onClick={() => setScanning(false)}
                                className="justify-center rounded-md bg-indigo-600 px-8 py-3 text-sm font-semibold leading-6 mt-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                > Turn off Camera</button>
                        ) : (
                            <button
                                onClick={() => setScanning(true)}
                                className="justify-center rounded-md bg-indigo-600 px-8 py-3 text-sm text-center font-semibold leading-6 mt-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >Scanning Card</button>
                        )}
                    </div>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Input Pembayaran
                    </h2>
                </div>
                    <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                                <label htmlFor="nama" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                    Nama Siswa
                                </label>
                                <div className="mt-0">
                                    <input
                                        type="text"
                                        name="nama"
                                        id="nama"
                                        value={formData.nama}
                                        onChange={(e) => setFormData({ ...formData, nama: e.target.value })}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                                        />
                                </div>
                            </div>
                            
                        </div>

                        <div className="sm:col-span-2  mt-2">
                            <label htmlFor="kelas" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                Kelas
                            </label>
                            <div className="mt-0">
                                <input
                                    type="text"
                                    name="kelas"
                                    id="kelas"
                                    value={formData.kelas}
                                    onChange={(e) => setFormData({ ...formData, kelas: e.target.value })}
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                    />
                            </div>
                        </div>

                        <div className="sm:col-span-2 mt-2">
                            <label htmlFor="nisn" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                NISN
                            </label>
                            <div className="mt-0">
                                <input 
                                    type="number"
                                    name="NISN"
                                    id="NISN"
                                    placeholder="masukkan NISN"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2 mt-2">
                            <label htmlFor="jenis_pembayaran" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                Jenis Bayar
                            </label>
                            <div className="mt-0">
                                <input 
                                    type="text"
                                    name="jenis_pembayaran"
                                    id="jenis_pembayaran"
                                    placeholder="Jenis Pembayaran"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2 mt-2">
                            <label htmlFor="jumlah_bayar" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                Jumlah Bayar
                            </label>
                            <div className="mt-0">
                                <input 
                                    type="number"
                                    name="jumlah_bayar"
                                    id="jumlah_bayar"
                                    placeholder="masukkan nominal"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2 mt-2">
                            <label htmlFor="bulan" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                Bulan
                            </label>
                            <div className="mt-0">
                                <select
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    name="bulan"
                                >
                                    <option value="Januari">Januari</option>
                                    <option value="Februari">Februari</option>
                                    <option value="Maret">Maret</option>
                                    <option value="April">April</option>
                                    <option value="Mei">Mei</option>
                                    <option value="Juni">Juni</option>
                                    <option value="Juli">Juli</option>
                                    <option value="Agustus">Agustus</option>
                                    <option value="September">September</option>
                                    <option value="Oktober">Oktober</option>
                                    <option value="November">November</option>
                                    <option value="Desember">Desember</option>
                                </select>
                            </div>
                        </div>

                        <div className="sm:col-span-2 flex items-center">
                            <label htmlFor="tanggal_bayar" className="block max-w-none text-left mr-16 font-semibold sm:text-sm leading-6 text-gray-900">
                                Tanggal Bayar
                            </label>
                            <div className="mt-2.5">
                                <DataPicker
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    selected={startDate} onChange={(date) => setStartDate(date)} 
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2 mt-2">
                            <label htmlFor="petugas_input" className="block text-left text-sm font-semibold leading-6 text-gray-900">
                                Petugas Input
                            </label>
                            <div className="mt-0">
                                <input
                                    type="text"
                                    name="petugas_input"
                                    id="petugas_input"
                                    placeholder="masukkan nama petugas"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 "
                                />
                            </div>
                        </div>
                    </form>
            </div>
        </div>
    )
}

export default PembayaranQR;