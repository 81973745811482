import { useState } from "react";

const { default: Card } = require("components/card")

const Cardjenisbayar = () => {
  const [data, setData] = useState({
    kode_pembayaran:'',
    jenis_transaksi:'',
    kelas:'X',
    nominal_total: 0,
    nominal_bulan: 0,
  });

  const [showNotification, setShowNotification] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${apiUrl}/v1/pembayaran/create`, {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if(!response.ok){
        throw new Error('Network response was not oke');
      }else{
        return response.json();
      }
    })
    .then((data) => {
      console.log('Success:', data);
      //clear data after succes hit
      setData({
        kode_pembayaran:'',
        jenis:'',
        kelas:'',
        nominal_total:0,
        nominal_bulan:0,
      });

      // Show success notification
      setShowNotification(true);

        // Hide notification after a few seconds (adjust as needed)
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);

    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };


    return(
        <Card extra={"w-full p-4 h-full"}>
          <div className=" w-full">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Jenis Pembayaran
            </h4>
            <p className="mt-2 text-base text-gray-600">
              Silahkan untuk Menambahkan Atau Mengubah data Jenis Pembayaran
            </p>
          </div>
        {/* Project 1 */}
        <div className="w-full items-center justify-between rounded-2xl bg-white p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
      {/* <div className="isolate bg-white px-6 py-24 sm:py-32"> */}
        <div className="items-center">
        <form onSubmit={handleSubmit}>
          <div className="sm:col-span-3 mt-2">
              <label htmlFor="kode_pembayaran" className="block text-sm font-semibold leading-6 text-gray-900">
                kode pembayaran
              </label>
              <div className="relative mt-0">
                <input
                  id="kode_pembayaran"
                  type="text"
                  name="kode_pembayaran"
                  value={data.kode_pembayaran}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                >
                </input>
              </div>
            </div>

          <div className="sm:col-span-3 mt-2">
              <label htmlFor="kelas" className="block text-sm font-semibold leading-6 text-gray-900">
                Kelas
              </label>
              <div className="relative mt-0">
                <select
                  id="kelas"
                  name="kelas"
                  value={data.kelas}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                    <option value="X">X</option>
                    <option value="XI">XI</option>
                    <option value="XII">XII</option>
                </select>
                </div>
            </div>

          <div className="sm:col-span-3 mt-2">
              <label htmlFor="jenis" className="block text-sm font-semibold leading-6 text-gray-900">
                Jenis Transaksi Bayar
              </label>
              <div className="relative mt-0">
                <input
                  id="jenis_transaksi"
                  type="text"
                  name="jenis_transaksi"
                  value={data.jenis_transaksi}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                >
                </input>
              </div>
              {/* <div className="relative mt-0">
                <select
                  id="jenis"
                  name="jenis"
                  value={data.jenis}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                    <option value="SPP">SPP</option>
                    <option value="Praktikum">Praktikum</option>
                    <option value="Uang Gedung">Uang Gedung</option>
                    <option value="Infaq">Infaq</option>
                    <option value="Laboratorium">Laboratorium</option>
                </select>
                </div> */}
            </div>

            <div className="sm:col-span-3 mt-2">
              <label htmlFor="nominal_total" className="block text-sm font-semibold leading-6 text-gray-900">
                Nominal Total
              </label>
              <div className="relative mt-0">
                <input
                  id="nominal_total"
                  type="number"
                  name="nominal_total"
                  value={data.nominal_total}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                />
                </div>
            </div>

            <div className="sm:col-span-3 mt-2">
              <label htmlFor="nominal_bulan" className="block text-sm font-semibold leading-6 text-gray-900">
                Nominal Bulan
              </label>
              <div className="relative mt-0">
                <input
                  id="nominal_bulan"
                  type="number"
                  name="nominal_bulan"
                  value={data.nominal_bulan}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                />
                </div>
            </div>

            <div className="mt-10">
            <button
                type="submit"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              Tambahkan Jenis Pembayaran
            </button>
        </div>
        {
          showNotification 
            && (
                <div className="notification">
                  <p>Success Adding Jenis Pembayaran</p>
                </div>
                )
        }
        </form>
        </div>
        </div>
    </Card>
    )
}

export default Cardjenisbayar;