import { Navigate, Route, Routes } from 'react-router-dom';

import AdminLayout from "layout/admin/";
import LandingPage from 'layout/landingpage';
import Login from 'layout/login';
import SignUpGuru from 'layout/signupguru';
import ConfigNilaiMaks from 'views/admin/configpage/ConfigNilaiMaksPemasukan';
import Denom from 'views/admin/configpage/Denom';
import PetugasPenarikanConfig from 'views/admin/configpage/PetugasPenarikan';
import CetakBulanan from 'views/admin/keuangan/cetak/cetakBulanan';
import DataKeuanganSiswa from 'views/admin/keuangan/detail/siswa';
import KurangBayarSiswa from 'views/admin/keuangan/detail/tableKurangBayarSiswa';
import TableKekuranganSiswa from 'views/admin/tablepage/tableKekuranganSiswa/TableKekuranganSiswa';
import TablePembayaranPraktikum from 'views/admin/tablepage/tablePraktikum/TablePembayaranPraktikum';
import TableSpp from 'views/admin/tablepage/tableSPP/TableSPP';
import TableSiswa from 'views/admin/tablepage/tableSiswa/TableSiswa';
import SignUp from './layout/signup';
// function App() {

const App = () => {
  return (
    <div>
      <Routes>
      <Route path="admin/*" element={<AdminLayout />}/>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<Login />}/>
      <Route path="/signup" element={<SignUp />}/>
      <Route path='/signupguru' element={<SignUpGuru />}/>
      <Route path="/denom-config" element={<Denom />}/>
      <Route path="/petugas-penarikan" element={<PetugasPenarikanConfig />}/>
      <Route path='/config-maks-pemasukan' element={<ConfigNilaiMaks />} />
      <Route path="/table-spp" element={<TableSpp />} />
      <Route path="/table-siswa" element={<TableSiswa />} />
      <Route path="/table-kekurangan" element={<TableKekuranganSiswa />} />
      <Route path="/table-pembayaran-praktikum" element={<TablePembayaranPraktikum />}/>
      <Route path='/data-keuangan-siswa' element={<DataKeuanganSiswa />}/>
      <Route path='/kurang-bayar-siswa' element={<KurangBayarSiswa />}/>
      <Route path='/cetak-bulanan' element={<CetakBulanan />} />
      <Route path='/home' element={<LandingPage />}/>
    </Routes>
    </div>
  );
};

export default App;
